
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'
import Icon from '@/components/Icon.vue'
import ColorText from '@/components/ColorText.vue'
import { saveSetting } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { liveSetting } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { add } from 'essential/tools/math'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'

export default defineComponent({
  name: 'TheOrderConfirm',
  emits: ['cancel', 'ok', 'close'],
  components: {
    DialogTemplate,
    Icon,
    ColorText,
    CurrentQuote,
    RealTimeQuote,
  },
  props: {
    order: Object,
  },
  setup (props, ctx) {
    const checked = shallowRef(false)

    const handleCancel = () => {
      ctx.emit('cancel')
      ctx.emit('close')
    }

    const handleOk = () => {
      if (checked.value) {
        liveSetting.value.orderConfirmation = 0
        saveSetting(liveSetting.value)
      }
      ctx.emit('ok')
      ctx.emit('close')
    }

    const calcStopPrice = (price: string, profit = false) => {
      const diff = profit ? props.order?.stopProfitOffset : -(props.order?.stopLossOffset ?? 0)
      return add(price, (diff || 0) * (props.order?.direction === 2 ? 1 : -1))
    }

    return {
      checked,

      handleCancel,
      handleOk,
      calcStopPrice,
    }
  },
})
