<template>
  <div class="bg-theme coupon-dialog">
    <div class="cd-head df-middle" style="height: 24px;">
      <t path="quickplaylive_38" class="c-title">My points:</t>
      <span class="f-bold">{{leftPoints}}pts</span>
    </div>

    <div class="cd-body mt-24">
      <HoldOn :content="couponList" :height="220">
        <div class="c-line df-between" v-for="(item, index) in couponList" :key="index">
          <div class="c-item df-col p-4 br-lg" v-for="(citem, cindex) in item" :key="cindex" @click="handleExchange(citem)">
            <div class="pic-wrap df-middle df-center">
              <Pic :src="citem.giftPic" width="80" height="56"/>
            </div>
            <p>{{ citem.poins }}pts</p>
            <div class="num px-8">
              {{citem.voucherNumber || 0}}
            </div>
          </div>
        </div>
      </HoldOn>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import * as R from 'ramda'
import { readExchangeCouponList, CouponItem } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import HoldOn from '@/provider/HoldOn.vue'
import { leftPoints, isAbleToPlay } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { noPointAlert } from '@/pages/liveForQuickPlay/components/liveAlert/liveAlert'
import ExchangeDialog from '@/modules/membership/components/ExchangeDialog.vue'
import { openDialog } from '@/components/popup/popup'
import { refreshAccount } from '@/modules/app/app'

export default defineComponent({
  name: 'TheExchangeCoupon',
  components: {
    Pic,
    HoldOn,
  },
  emits: ['close'],
  setup (props, ctx) {
    const couponList = shallowRef()

    const sortGift = R.sortBy(R.prop('giftSort'))

    const refreshList = () => {
      readExchangeCouponList({
        page: 0,
        pageCount: 100,
      }).then((resp) => {
        const list = sortGift(resp.noVoucherModels)
        couponList.value = R.splitEvery(3, list)
      })
    }

    refreshList()

    const handleExchange = (item: CouponItem) => {
      if (!isAbleToPlay()) {
        ctx.emit('close')
        return false
      }

      if (leftPoints.value < item.poins) {
        noPointAlert()
      } else {
        openDialog(ExchangeDialog, {
          gift: item,
          onClose: () => {
            refreshList()
            refreshAccount()
          },
        })
      }
    }

    return {
      couponList,
      leftPoints,
      handleExchange,
    }
  },
})
</script>

<style scoped lang='scss'>
.coupon-dialog{
  padding: 20px 16px;

  .cd-body{
    .c-line{
      margin-bottom: 21px;
      &:last-child{
        margin-bottom: 0px;
      }
      .c-item{
        border: 1px solid #333;
        position: relative;
        border: 1px solid var(--color-border);
        .pic-wrap{
          width: 96px;
          height: 72px;
          background: var(--color-light);
        }
        .num{
          position: absolute;
          top: -9px;
          left: 0;
          height: 18px;
          background: var(--color-success);
          border-radius: 9px;
          color: var(--color-white);
        }
        p{
          text-align: center;
          line-height: 20px;
          margin-top: 4px;
        }
      }
    }
  }
}
</style>
