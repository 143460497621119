
import { defineComponent, shallowRef, nextTick, shallowReactive } from 'vue'
import * as R from 'ramda'
import NIMChat from 'common/chat/NIMChat.vue'
import { NIMMessage } from 'common/chat/chat.api'
import { IMConfig, MsgType, liveSetting } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { renderEmoji } from '@/modules/community/communities/community'
import Icon from '@/components/Icon.vue'
import { emit } from 'essential/tools/event'
import { events, keymap } from '@/config'
import { localGet } from 'essential/store/localStore'

export default defineComponent({
  name: 'TheChatContent',
  components: {
    Icon,
    NIMChat,
  },
  setup () {
    const listEl = shallowRef<HTMLElement>()

    const messageList = shallowReactive<Array<NIMMessage>>([])

    const scrollToBottom = (behavior: 'auto' | 'smooth') => {
      if (listEl.value) {
        listEl.value.scroll({
          top: listEl.value?.scrollHeight,
          behavior: behavior,
        })
      }
    }

    const flush = (msgs: Array<NIMMessage>, isNew?: boolean) => {
      if (isNew) {
        for (let i = 0; i < msgs.length; i++) {
          const msgSplit = msgs[i].text?.split('#wt-msg#') || []
          if (msgSplit?.length > 1) {
            const type = msgSplit[0]
            if (type === MsgType.GIFT) {
              emit(events.liveGiftMsg, msgSplit[1])
            } else if (type === MsgType.LIMITED_TIME_DEPOSIT) {
              emit(events.liveDepositAct, msgSplit[1])
            } else if (type === MsgType.LIMITED_TIME_TRADE) {
              emit(events.liveTradeAct, msgSplit[1])
            } else if (type === MsgType.DELETE_MSG) {
              const index = R.findIndex(R.propEq('idClient', msgSplit[1]))(messageList)
              messageList.splice(index, 1)
            }
          }
        }
      }
      const message_ = []
      const now = Date.now()
      const today = now - (now % (1000 * 60 * 60 * 24))
      for (let i = msgs.length - 1; i >= 0; i--) {
        const msg = msgs[i]
        if (R.isEmpty(msg.text)) continue

        if ((msg.text || '').split('#wt-msg#').length > 1) continue

        if (liveSetting.value.hideLikesAndGifts) {
          const name = (msg.text || '').slice(1, -1)
          if (name.split('*')[0] === 'live_like' && msg.fromNick !== localGet(keymap.user.nickName)) {
            continue
          }
        }

        if ((msg.text || '').split('#system#').length === 3) {
          msg.text = (msg.text || '').split('#system#')[1]
          msg.isSystem = true
        }

        if (msg.time > today) {
          message_.push(msg)
        }
      }

      messageList.push(...message_)

      nextTick(() => {
        if (listEl.value) {
          scrollToBottom('smooth')
        }
      })
    }

    const parseCustom = (str: string) => {
      try {
        const json = JSON.parse(str)
        if ('level' in json) {
          return {
            hasLevel: true,
            level: json.level,
          }
        }
      } catch {
        return {
          hasLevel: false,
          level: 0,
        }
      }
    }

    return {
      messageList,
      IMConfig,
      listEl,

      flush,
      renderEmoji,
      parseCustom,
    }
  },
})
