<template>
<div>
<DialogTemplate no-title>
  <div class="df-col df-center df-middle mb-32 mt-24 fill">
    <Pic src="live/live_like" class="mb-24" />
    <t as="div" path="quickplaylive_39" multiline #="{td}" custom class="t-center">
      <p v-for="(p, index) in td" :key="index" class="mb-8">{{p}}</p>
    </t>
  </div>

  <template #controls>
    <router-link custom #="{navigate}" :to="`/eventsList/bigNews?id=${eventId}&type=${type}`">
      <button class="flex-1 btn block" @click="handleCancel">
        <t path="quickplaylive_40" custom #="{td}">
          {{ td || 'Later' }}
        </t>
      </button>
    </router-link>
    <button class="flex-1 btn block primary" @click="handleOk">
      <t path="quickplaylive_41" custom #="{td}">
        {{ td || 'Got it' }}
      </t>
    </button>
  </template>
</DialogTemplate>
</div>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheLikeDialog',
  emits: ['cancel', 'ok', 'close'],
  components: { DialogTemplate, Pic },
  setup (props, ctx) {
    const handleCancel = () => {
      ctx.emit('cancel')
      ctx.emit('close')
    }

    const handleOk = () => {
      ctx.emit('ok')
      ctx.emit('close')
    }

    return {
      handleCancel,
      handleOk,
    }
  },
})
</script>

<style scoped>

</style>
