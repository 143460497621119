<template>
  <div class="bg-theme coupon-dialog">
    <div class="cd-head df-middle" style="height: 24px;">
      <t path="quickplaylive_64" class="c-title">My points:</t>
      <span class="f-bold">{{leftPoints}}pts</span>
    </div>

    <div class="cd-body mt-24">
      <HoldOn :content="giftList" :height="220">
        <Swipe #="{item}" :list="giftList" dot dotDark style="height:264px">
          <div class="c-line d-f"
            v-for="(pitem, index) in item" :key="index"
          >
            <div class="c-item df-col p-4 br-lg"
              v-for="(citem, cindex) in pitem" :key="cindex"
              @click="handleExchange(citem)"
              :style="`margin-right: ${(cindex + 1) % 3 === 0 ? '0' : 'calc( calc(100vw - 350px) / 2)'}`"
            >
              <div class="pic-wrap df-middle df-center">
                <Pic :src="citem.giftPic" width="80" height="56"/>
              </div>
              <p>{{ citem.poins }}pts</p>
            </div>
          </div>
        </Swipe>
      </HoldOn>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import * as R from 'ramda'
import { readGiftList, GiftItem } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import HoldOn from '@/provider/HoldOn.vue'
import { leftPoints, isAbleToPlay } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { noPointAlert } from '@/pages/liveForQuickPlay/components/liveAlert/liveAlert'
import TheGiftConfirmDialog from '@/pages/liveForQuickPlay/components/TheGiftConfirmDialog.vue'
import { openDialog } from '@/components/popup/popup'
import Swipe from '@/components/Swipe.vue'

export default defineComponent({
  name: 'TheGiftDialog',
  components: {
    Pic,
    HoldOn,
    Swipe,
  },
  emits: ['close'],
  setup (props, ctx) {
    const giftList = shallowRef()
    const sortGift = R.sortBy(R.prop('giftSort'))
    const refreshList = () => {
      readGiftList({
        page: 0,
        pageCount: 100,
      }).then((resp) => {
        const list = sortGift(resp || [])
        giftList.value = R.splitEvery(3, list)
        giftList.value = R.splitEvery(2, giftList.value)
      })
    }

    refreshList()

    const handleExchange = (item: GiftItem) => {
      if (!isAbleToPlay()) {
        ctx.emit('close')
        return false
      }

      if (leftPoints.value < item.poins) {
        noPointAlert()
      } else {
        openDialog(TheGiftConfirmDialog, {
          gift: item,
          onClose: () => {
            ctx.emit('close')
          },
        }, {
          touchToClose: true,
        })
      }
    }

    return {
      giftList,
      leftPoints,
      handleExchange,
    }
  },
})
</script>

<style scoped lang='scss'>
.coupon-dialog{
  padding: 20px 16px;

  .cd-body{
    .c-line{
      margin-bottom: 21px;
      &:last-child{
        margin-bottom: 0px;
      }
      .c-item{
        border: 1px solid #333;
        position: relative;
        border: 1px solid var(--color-border);
        .pic-wrap{
          width: 96px;
          height: 72px;
          background: var(--color-light);
        }
        .num{
          position: absolute;
          top: -9px;
          left: 0;
          height: 18px;
          background: var(--color-success);
          border-radius: 9px;
          color: var(--color-white);
        }
        p{
          text-align: center;
          line-height: 20px;
          margin-top: 4px;
        }
      }
    }
  }
}
</style>
