<template>
  <div class="btm-view bg-theme">
    <Transition name="slide-up">
      <div class="state-item pt-4" v-if="view === 'quote'">
        <TheCodeSelector :code="curruntCode" />
      </div>
      <div class="state-item" v-else-if="view === 'rank'">
        <div class="df-middle df-between fill px-12" @click="handleExpand">
          <div class="df-middle">
            <Pic src="live/goldcoin" height="38" width="40" />
            <p class="ml-4">
              <t path="quickplaylive_12" custom="username, number">
                <template #username>
                  <span>{{topName}}</span>
                </template>
                <template #number>
                  <money :v="topEarn" :class="{'c-success': topEarn >=0 ,'c-danger': topEarn<0}"></money>
                </template>
              </t>
            </p>
          </div>
          <Icon :name="isExpand ? 'caret-down' : 'caret-up'" class="f-xl" />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang='ts'>
import { openDialog } from '@/components/popup/popup'
import { defineComponent, onMounted, onUnmounted, shallowRef } from 'vue'
import TheQuickPlayInLive from '@/pages/liveForQuickPlay/components/TheQuickPlayInLive.vue'
import TheCodeSelector from '@/pages/liveForQuickPlay/components/quickPlayInLive/TheCodeSelector.vue'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'

import { isAbleToPlay, isExpand } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { readMaxProfit } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheBottomView',
  components: {
    TheCodeSelector,
    Pic,
    Icon,
  },
  setup () {
    const view = shallowRef('quote')
    const topName = shallowRef('')
    const topEarn = shallowRef(0)
    const route = useRoute()

    let timer = 0

    const interval = [1000 * 60 * 5, 1000 * 5]
    // const interval = [1000 * 10, 1000 * 2]

    const curruntCode = shallowRef('')

    enum ViewIndex {
      QUOTE,
      RANK,
    }

    let intervalIndex = ViewIndex.QUOTE

    onMounted(() => {
      intervalLoop()
    })

    const intervalLoop = () => {
      if (intervalIndex === ViewIndex.QUOTE) {
        view.value = 'quote'
        clearTimeout(timer)
        timer = window.setTimeout(() => {
          intervalIndex = ViewIndex.RANK
          readMaxProfit({
            roomid: route.params.chatRoomId,
          }).then((res) => {
            topName.value = (res.userName || '').substr(0, 3) + '******'
            topEarn.value = res.amount || 0
            view.value = 'rank'
          }).finally(() => {
            intervalLoop()
          })
        }, interval[intervalIndex])
      } else {
        clearTimeout(timer)
        timer = window.setTimeout(() => {
          view.value = 'quote'
          intervalIndex = ViewIndex.QUOTE
          intervalLoop()
        }, interval[intervalIndex])
      }
    }

    onUnmounted(() => {
      clearInterval(timer)
    })

    const handleExpand = () => {
      if (!isAbleToPlay()) {
        return
      }
      isExpand.value = !isExpand.value
      if (isExpand.value) {
        openDialog(TheQuickPlayInLive, {}, {
          touchToClose: true,
        })
      }
    }

    return {
      view,
      topName,
      topEarn,
      curruntCode,
      isExpand,
      handleExpand,
    }
  },
})
</script>

<style scoped lang='scss'>
.btm-view{
  max-height: 56px;
  min-height: 56px;
  display: inline-block;
  position: relative;
}
.state-item{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  transform: rotateX(180deg)
}

.slide-up-leave-to {
  transform: rotateX(0deg)
}
</style>
