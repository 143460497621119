
import { defineComponent, watch, shallowRef, nextTick, onUnmounted } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheGiftAnimation',
  components: {
    Pic,
  },
  props: {
    gift: Object,
  },
  setup (props) {
    const showGiftAni = shallowRef(false)
    const aniDom = shallowRef<HTMLElement | null>(null)
    let timer = 0

    watch(() => props.gift, () => {
      if (aniDom.value) {
        aniDom.value.style.display = 'block'
      }
      if (props.gift) {
        nextTick(() => {
          showGiftAni.value = true
          timer = window.setTimeout(() => {
            showGiftAni.value = false
            if (aniDom.value) {
              aniDom.value.style.display = 'none'
            }
          }, 2000)
        })
      }
    }, {
      immediate: true,
      deep: true,
    })

    onUnmounted(() => {
      clearTimeout(timer)
      if (aniDom.value) {
        aniDom.value.style.display = 'none'
      }
    })

    return {
      showGiftAni,
      aniDom,
    }
  },
})
