<template>
  <DialogTemplate @cancel="$emit('close')" no-title>
    <div class="flex-1 pt-24">
      <div class="cd-head df-middle" style="height: 24px;">
        <t path="quickplaylive_65" class="c-title">My points:</t>
        <span class="f-bold">{{leftPoints}}pts</span>
      </div>
      <div class="df-col df-middle mt-32">
        <div class="c-item df-col p-4 br-lg df-middle">
          <div class="pic-wrap df-middle df-center">
            <Pic :src="gift.giftPic" width="80" height="56"/>
          </div>
          <p>{{ gift.poins }}pts</p>
        </div>

        <div class="df-middle mb-32 mt-24 input-wrap br-lg px-8">
          <Icon :class="{'c-tip': numValue <= 1}" name="minus" @click="numValue > 1 && change(-1)" />
          <input type="number" class="buy-num" :max="20" v-model="numValue" @input="handleInput" />
          <Icon :class="{'c-tip': disabled}" name="plus" @click="numValue < 20 && change(1)" />
        </div>
      </div>
    </div>
    <template #controls>
      <Button class="flex-1 primary" :class="{'disabled': changePoints === 0}" @click="submit" :progress="progress">
        <t path="quickplaylive_66" :args="{donnum: changePoints}" #="{td}" custom>
          {{td || `Donate ${changePoints} pts`}}
        </t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import { refreshAccount } from '@/modules/app/app'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, ref, shallowRef, h, render } from 'vue'
import useRequest from '@/hooks/useRequest'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { leftPoints, liveRoomInfo, useIMsend, IMConfig } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { exchangeGift } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import TheGiftAnimation from '@/pages/liveForQuickPlay/components/liveAlert/TheGiftAnimation.vue'

export default defineComponent({
  name: 'TheGiftConfirmDialog',
  components: {
    Pic,
    Icon,
    Button,
    DialogTemplate,
  },
  emits: ['close'],
  props: {
    gift: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const numValue = ref(1)
    const [commit, progress] = useRequest(exchangeGift)
    const basePoint = props.gift.poins || 0
    const changePoints = shallowRef(props.gift.poins)
    const disabled = shallowRef(false)

    const change = (dir: number) => {
      if (dir < 0) {
        numValue.value = Number(numValue.value) - 1
      } else {
        if ((Number(numValue.value) + 1) * basePoint < leftPoints.value) {
          numValue.value = Number(numValue.value) + 1
        }
      }
      changePoints.value = basePoint * numValue.value
      disableCheck()
    }

    const disableCheck = () => {
      if ((Number(numValue.value) + 1) * basePoint < leftPoints.value) {
        disabled.value = false
      } else {
        disabled.value = true
      }
    }

    const submit = () => {
      // handleSendGift()
      commit({
        giftId: props.gift.giftId,
        giftNum: numValue.value,
        roomid: liveRoomInfo.value.roomid,
      }).then((res) => {
        if (res.number > 0) {
          handleSendGift()
        }
      }).finally(() => {
        refreshAccount()
        ctx.emit('close')
      })
    }

    const handleSendGift = () => {
      const params = {
        ...props.gift,
        count: numValue.value,
        nickname: IMConfig.value?.name,
        stamp: new Date().valueOf(),
      }

      render(h(TheGiftAnimation, {
        gift: params,
      }), document.body)

      const msg = 'gift#wt-msg#' + JSON.stringify(params)
      useIMsend(msg)
      ctx.emit('close')
    }

    const handleInput = () => {
      if (typeof Number(numValue.value) !== 'number') {
        numValue.value = 1
        return false
      }

      const maxExchange = Math.floor(leftPoints.value / basePoint)

      if (numValue.value > maxExchange || numValue.value > 20) {
        numValue.value = maxExchange <= 20 ? maxExchange : 20
      }

      changePoints.value = basePoint * numValue.value
    }

    return {
      progress,
      numValue,
      changePoints,
      leftPoints,
      submit,
      change,
      handleInput,
      disabled,
    }
  },
})
</script>

<style scoped lang="scss">
.buy-num {
  width: 64px;
  height: 24px;
  padding: 0;
  border: none;
  background: var(--color-background);
  text-align: center;
  outline: none;
  font-weight: bold;
  margin: 0 4px;
}
.input-wrap{
  background: var(--color-background);
  height: 40px;
  border: 1px solid var(--color-border);
}

.c-item{
  border: 1px solid #333;
  position: relative;
  border: 1px solid var(--color-border);
  .pic-wrap{
    width: 96px;
    height: 72px;
    background: var(--color-light);
  }
  p{
    text-align: center;
    line-height: 20px;
    margin-top: 4px;
  }
}
</style>
