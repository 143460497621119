
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import * as R from 'ramda'
import { readExchangeCouponList, CouponItem } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import HoldOn from '@/provider/HoldOn.vue'
import { leftPoints, isAbleToPlay } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { noPointAlert } from '@/pages/liveForQuickPlay/components/liveAlert/liveAlert'
import ExchangeDialog from '@/modules/membership/components/ExchangeDialog.vue'
import { openDialog } from '@/components/popup/popup'
import { refreshAccount } from '@/modules/app/app'

export default defineComponent({
  name: 'TheExchangeCoupon',
  components: {
    Pic,
    HoldOn,
  },
  emits: ['close'],
  setup (props, ctx) {
    const couponList = shallowRef()

    const sortGift = R.sortBy(R.prop('giftSort'))

    const refreshList = () => {
      readExchangeCouponList({
        page: 0,
        pageCount: 100,
      }).then((resp) => {
        const list = sortGift(resp.noVoucherModels)
        couponList.value = R.splitEvery(3, list)
      })
    }

    refreshList()

    const handleExchange = (item: CouponItem) => {
      if (!isAbleToPlay()) {
        ctx.emit('close')
        return false
      }

      if (leftPoints.value < item.poins) {
        noPointAlert()
      } else {
        openDialog(ExchangeDialog, {
          gift: item,
          onClose: () => {
            refreshList()
            refreshAccount()
          },
        })
      }
    }

    return {
      couponList,
      leftPoints,
      handleExchange,
    }
  },
})
