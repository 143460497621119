
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheLiveGuideContent',
  components: { Pic },
  props: {
    s: {
      type: Number,
      default: 1,
    },
  },
})
