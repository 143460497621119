<template>
  <div class="panel bg-theme py-12">
    <Matrix
      :list="productList" :select="curruntCode"
      class="px-16 pt-12"
      :column="2" :col-gap="2" #="{ item, active }"
    >
      <ChoiceButton
        class="product" @click="handleChose(item)"
        :class="{active: product === item}"
      >
        <div class="p-r i-wrap df-middle df-center">
          <span>{{item.contract}}</span>
          <div class="close-state f-md df-middle" v-if="item.isClosed == 2">
            closed
          </div>
        </div>
      </ChoiceButton>
    </Matrix>
  </div>
</template>

<script lang='ts'>
import ChoiceButton from '@/components/ChoiceButton.vue'
import { ProductSchema } from '@/modules/fastTrade/fastTrade.api'
import Matrix from 'common/Matrix.vue'
import * as R from 'ramda'
import { defineComponent, shallowRef, toRaw, watch } from 'vue'
import { curruntCode, productList } from '@/pages/liveForQuickPlay/liveForQuickPlay'

export default defineComponent(
  {
    name: 'TheCodeSelector',
    components: { ChoiceButton, Matrix },
    props: {
      type: Number,
    },
    emits: ['change', 'close'],
    setup (props, ctx) {
      const product = shallowRef<ProductSchema | null>(null)

      product.value = R.find(
        R.propEq('contract', curruntCode.value), productList.value,
      ) ?? productList.value[0]

      watch(() => product.value, () => {
        if (product.value) {
          ctx.emit('change', toRaw(product.value))
          curruntCode.value = toRaw(product.value).contract
        }
      })

      const handleChose = (item: ProductSchema) => {
        product.value = item
        ctx.emit('close')
      }

      return {
        product,
        productList,
        curruntCode,
        handleChose,
      }
    },
  },
)
</script>

<style scoped lang='scss'>
.panel {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.3);
}

::v-deep(.product) {
  height: 36px;
  line-height: 28px;
}
::v-deep(.choice) {
  padding: 0 !important;
}
.i-wrap{
  height: 100%;
}
.close-state{
  position: absolute;
  top: -1px;
  left: -1px;
  color: var(--color-white);
  background: var(--color-border);
  border-top-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 20px;
  padding: 0 4px;
}
</style>
