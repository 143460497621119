<template>
  <div v-if="shwoTrueInput" class="true-wrap" @click="handleHideTrueInput">
    <div class="true-input df-middle px-12" @click.stop="">
      <div class="input-wrap flex-1 df-middle pr-8">
        <div class="fast-chat p-6" :class="{'active': showFastChat}" @click.stop="openFastChat">
          <Icon name="chat" class="c-white" />
        </div>
        <input id="trueInput" type="text" v-model="text" maxlength="40" class="flex-1 px-4">
        <Icon name="smile" class="ml-8" @click="useEmoji = !useEmoji" />
      </div>
      <Button class="send-btn btn primary px-16 ml-8" @click="comment">
        <t path="live_6">send</t>
      </Button>
    </div>
    <TheEmoji v-show="useEmoji" v-model:value="text" @click.stop=""/>
  </div>

  <div v-else class="msg-main df-middle px-12">
    <div class="fake-input flex-1 df-middle pr-6" :class="{'bg-black active': showFastChat, 'nm-bg': !showFastChat}">
      <div class="fast-chat p-6" :class="{'active': showFastChat}" @click.stop="openFastChat">
        <Icon name="chat" class="c-white" />
      </div>
      <t as="p" path="quickplaylive_11" class="flex-1 c-white pl-4 f-md" @click="openTrueInput(false)">Send Message</t>
      <Icon name="smile" class="ml-8 c-white" @click="openTrueInput(true)" />

      <div class="fast-list py-12 px-8" v-if="showFastChat">
        <div class="fast-item c-white"
          v-for="(item, index) in fastList" :key="index"
          @click="sendFastChat(item.comment)"
        >
          {{ item.comment }}
        </div>
      </div>
    </div>
    <div class="coupon-btn df-middle df-center mx-8"
      :class="{'red-icon': showRedPoint}" @click="openCouponDialog"
    >
      <Pic src="live/live_coupon" width="24" height="24" />
    </div>
    <TheLike />
    <div class="gift-btn df-middle df-center mx-8" @click="openGiftDialog">
      <Pic src="live/gift_toggle" width="24" height="24" />
    </div>
    <div class="setting df-middle df-center p-6">
      <Icon name="settings" class="c-white" @click="openSettingDialog" />
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, shallowRef, nextTick, onBeforeUnmount, onMounted } from 'vue'
import TheLike from '@/pages/liveForQuickPlay/components/TheLike.vue'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'
import TheEmoji from '@/modules/community/communities/components/TheEmoji.vue'
import { openDialog } from '@/components/popup/popup'

import TheLiveSetting from '@/pages/liveForQuickPlay/components/TheLiveSetting.vue'
import TheExchangeCoupon from '@/pages/liveForQuickPlay/components/TheExchangeCoupon.vue'
import TheGiftDialog from '@/pages/liveForQuickPlay/components/TheGiftDialog.vue'
import { readExchangeCouponList, readFastCommentList } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { leftPoints, useIMsend } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'TheMessages',
  components: {
    TheLike,
    Pic,
    Icon,
    TheEmoji,
    Button,
  },
  setup () {
    const shwoTrueInput = shallowRef(false)
    const useEmoji = shallowRef(false)
    const text = shallowRef('')
    const showFastChat = shallowRef(false)
    const showRedPoint = shallowRef(false)

    const fastList = shallowRef<{ comment: string }[]>([])

    const openFastChat = () => {
      shwoTrueInput.value = false
      useEmoji.value = false
      if (fastList.value.length === 0) {
        return
      }
      showFastChat.value = !showFastChat.value
    }

    readExchangeCouponList({
      page: 0,
      pageCount: 100,
    }).then((resp) => {
      resp.noVoucherModels.forEach(item => {
        if (item.poins < leftPoints.value) {
          showRedPoint.value = true
        }
      })
    })

    readFastCommentList().then(res => {
      fastList.value = res || []
    })

    const openTrueInput = (withemoji?: boolean) => {
      shwoTrueInput.value = true

      if (withemoji) {
        useEmoji.value = true
      } else {
        nextTick(() => {
          const trueInput = document.getElementById('trueInput')
          trueInput?.focus()
        })
      }
    }

    const openSettingDialog = () => {
      openDialog(TheLiveSetting, null, {
        touchToClose: true,
      })
    }

    const openCouponDialog = () => {
      showRedPoint.value = false
      openDialog(TheExchangeCoupon, null, {
        touchToClose: true,
      })
    }

    const openGiftDialog = () => {
      openDialog(TheGiftDialog, null, {
        touchToClose: true,
      })
    }

    /* 通过接口发送消息---先发后删功能 */
    const sending = shallowRef(false)
    const comment = () => {
      if (sending.value) return
      sending.value = true

      text.value = text.value.replace(/#wt-msg#/g, '') // 过滤自定义特殊消息，避免用户手动输入触发特效
      text.value = text.value.replace(/#system#/g, '') // 过滤自定义特殊消息，避免用户手动输入触发特效

      useIMsend(text.value, () => {
        text.value = ''
        sending.value = false
        shwoTrueInput.value = false
        useEmoji.value = false
      })
    }

    const handleHideTrueInput = () => {
      shwoTrueInput.value = false
      useEmoji.value = false
    }

    const sendFastChat = (text: string) => {
      useIMsend(text, () => {
        shwoTrueInput.value = false
        useEmoji.value = false
        showFastChat.value = false
      })
    }

    onMounted(() => {
      window.addEventListener('click', () => {
        if (showFastChat.value) {
          showFastChat.value = false
        }
      })
    })

    onBeforeUnmount(() => {
      window.removeEventListener('click', () => {
        // remove listener
      })
    })

    return {
      shwoTrueInput,
      useEmoji,
      text,
      showFastChat,
      showRedPoint,
      fastList,

      comment,
      openFastChat,
      openTrueInput,
      openSettingDialog,
      openCouponDialog,
      openGiftDialog,
      handleHideTrueInput,
      sendFastChat,
    }
  },
})
</script>

<style scoped lang='scss'>
.msg-main{
  height: 48px;
  .coupon-btn{
    min-width: 36px;
    max-width: 36px;
    height: 36px;
    background-color: var(--color-white);
    border-radius: 50%;
    position: relative;

    &.red-icon{
      &::after {
        top: 0px;
        right: 2px;
        position: absolute;
        display: inline-block;
        width: 8px;
        height: 8px;
        background: var(--color-danger);
        border-radius: 8px;
        content: '';
      }
    }
  }

  .gift-btn{
    min-width: 36px;
    max-width: 36px;
    height: 36px;
    border-radius: 50%;
    background: linear-gradient(38deg, #FF004E 0%, #FF694C 100%);
  }
}

.true-wrap{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  // align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
}
.true-input{
  background-color: var(--color-white);
  height: 48px;
  width: 100%;
  .input-wrap{
    height: 36px;
    border-radius: 18px;
    background-color: var(--color-grey);
    input{
      outline: none;
      list-style: none;
      border: none;
      background-color: var(--color-grey);
      // color: var(--color-white);
    }
  }
  .send-btn{
    height: 36px;
    border-radius: 18px;
  }
}

.fake-input{
  // background: rgba(24, 29, 36, 0.6);
  z-index: 1;
  height: 36px;
  border-radius: 18px;
  position: relative;
  .fast-list{
    position: absolute;
    z-index: 0;
    background: var(--color-black);
    width: 100%;
    bottom: 36px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    .fast-item{
      border-bottom: 1px solid var(--color-white);
      line-height: 32px;
    }
  }
  &.nm-bg{
    background: rgba(24, 29, 36, 0.6);
  }
  &.active{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.setting{
  background: rgba(24, 29, 36, 0.6);
  border-radius: 50%;
  // margin-left: 52px;
}

.fast-chat{
  background: rgba(24, 29, 36, 0.6);
  border-radius: 50%;
  &.active{
    background: var(--color-primary);
  }
}
</style>
