<template>
  <div class="bg-theme">
    <div class="px-16">
      <p class="t-center f-bold f-lg py-12">
        <t path="quickplaylive_68">rank</t>
      </p>
      <Line />
    </div>

    <div class="d-body fill">
      <div class="rank-tab">
        <TheCupTabSwitch
          :tabs="[translate('quickplaylive_69'), translate('quickplaylive_70')]"
          :active="tabIndex"
          @switch="handleChange"/>
      </div>

      <div class="list-wrap px-16 pb-12">
          <Holder :content="curruntList">
            <Nullable :content="curruntList">
              <div v-for="item in curruntList" :key="item.rank" class="df-middle t-center column">
                <div class="col-avatar mr-16">
                  <TheRankNumber v-if="item.rank < 4" :rank="item.rank" />
                  <span v-else class="f-bold">{{ item.rank }}</span>
                </div>
                <Pic :src="item.avatarPic" fallback="/img/user/Avatar@2x.png"
                  width="32" height="32" class="mr-16" style="border-radius: 50%;" />
                <div class="flex-1 col-content df-middle">
                  <div class="flex-1 t-left">
                    {{item.nickName}}
                  </div>
                  <span class="mr-8 f-bold c-primary">
                    {{item.consumePoints}}
                  </span>
                </div>
              </div>
            </Nullable>
          </Holder>
      </div>

      <div v-if="mine.consumePoints" class="mine bg-primary df-middle pr-16 c-white">
        <div class="col-avatar mx-16">
          <TheRankNumber v-if="mine.rank < 4 && mine.rank > 0" :rank="mine.rank" />
          <span v-else>{{ mine.rank }}</span>
        </div>
        <Pic :src="mine.avatarPic" width="32" height="32" class="mr-16" style="border-radius: 50%;" />
        <div class="flex-1 df-middle">
          <div class="flex-1 t-left">
            {{mine.nickName}}
          </div>
          <span class="mr-8 f-bold c-white">
            <t path="quickplaylive_71">Donate:</t>
            {{mine.consumePoints}}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, shallowRef } from 'vue'
import TheRankNumber from '@/modules/weTradeCup/components/TheRankNumber.vue'
import TheCupTabSwitch from '@/modules/weTradeCup/components/TheCupTabSwitch.vue'
import Line from '@/components/Line.vue'
import Pic from '@/components/Pic.vue'
import Nullable from '@/components/Nullable.vue'
import Holder from '@/provider/Holder.vue'
import { readLiveRank } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { liveRoomInfo } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'TheRankDialog',
  components: {
    Line,
    Pic,
    Holder,
    Nullable,
    TheRankNumber,
    TheCupTabSwitch,
  },
  setup () {
    const tabIndex = shallowRef(0)

    const curruntList = shallowRef()

    const mine = shallowRef({
      avatarPic: '',
      nickName: '',
      consumePoints: 0,
      rank: 0,
    })

    const handleChange = () => {
      tabIndex.value = Number(!tabIndex.value)
      getRankList()
    }

    const getRankList = () => {
      readLiveRank({
        roomid: liveRoomInfo.value.roomid,
        type: tabIndex.value,
      }).then(res => {
        curruntList.value = res.liveSendGiftRankInfoModels
        mine.value.avatarPic = res.avatarPic
        mine.value.nickName = res.nickName
        mine.value.consumePoints = res.consumePoints
        mine.value.rank = res.rank
      })
    }
    getRankList()

    return {
      mine,
      tabIndex,
      curruntList,
      handleChange,
      translate,
    }
  },
})
</script>

<style scoped lang='scss'>
::v-deep(.tab-item){
  color: var(--color-theme-reverse) !important;
}
::v-deep(.tab-item.active){
  font-size: 15px !important;
}
::v-deep(.s-line){
  background: var(--color-primary) !important;
  height: 2px !important;
}

.list-wrap{
  overflow-y: scroll;
  max-height: 50vh;
}

.col-rank {
  min-width: 16px;
}

.col-avatar {
  min-width: 32px;
}

.col-content {
  height: 56px;
  border-bottom: 1px solid var(--color-grey);
}
.mine {
  height: 56px;
}
</style>
