<template>
  <DialogTemplate no-title>
    <div class="form pt-16 pb-24">
      <div class="f-item df-middle df-between">
        <t class="lable f-bold" path="quickplaylive_43">Name Displayed</t>
        <div class="content">
          <input type="text" v-model.trim="liveSetting.name" class="px-8" :maxlength="20">
        </div>
      </div>
      <div class="f-item df-middle df-between">
        <t class="lable f-bold" path="quickplaylive_44">Order Confirmation</t>
        <div class="content">
          <Switch :checked="liveSetting.orderConfirmation === 1"
            @change="liveSetting.orderConfirmation = Number(!liveSetting.orderConfirmation)" />
        </div>
      </div>
      <!-- <div class="f-item df-middle df-between">
        <t class="lable f-bold" path="quickplaylive_45">Background playback</t>
        <div class="content">
          <Switch :checked="liveSetting.blackgroundPlayback === 1"
            @change="liveSetting.blackgroundPlayback = Number(!liveSetting.blackgroundPlayback)" />
        </div>
      </div> -->
      <div class="f-item df-middle df-between">
        <t class="lable f-bold" path="quickplaylive_46">Hide Likes and Gifts</t>
        <div class="content">
          <Switch :checked="liveSetting.hideLikesAndGifts === 1"
            @change="liveSetting.hideLikesAndGifts = Number(!liveSetting.hideLikesAndGifts)" />
        </div>
      </div>
    </div>
    <template #controls>
      <Button class="block primary btn" @click="handleSave" :progress="progress">
        <t path="quickplaylive_47" #="{td}" custom>{{ td || 'Save' }}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import Switch from '@/modules/membership/signal/components/Switch.vue'
import Button from '@/components/Button.vue'
import { keymap } from '@/config'
import { localSet } from 'essential/store/localStore'

import { liveSetting, IMConfig, refreshIMconfig } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { useSaveLiveSetting, readSetting } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheLiveSetting',
  components: {
    DialogTemplate,
    Switch,
    Button,
  },
  setup (props, ctx) {
    const { request, progress } = useSaveLiveSetting()
    const route = useRoute()

    readSetting().then(res => {
      liveSetting.value = res
    })

    const handleSave = () => {
      if (liveSetting.value.name !== IMConfig.value?.name) {
        IMConfig.value = null
      }
      request(liveSetting.value).then(() => {
        localSet(keymap.user.nickName, liveSetting.value.name)
        if (IMConfig.value) {
          IMConfig.value.name = liveSetting.value.name as string
        }
        refreshIMconfig(route.params.chatRoomId as string)
        ctx.emit('close')
      })
    }

    return {
      liveSetting,
      progress,
      handleSave,
    }
  },
})
</script>

<style scoped lang='scss'>
.form{
  width: 100%;
  .f-item{
    height: 56px;
    .content{
      input{
        width: 160px;
        height: 40px;
        border-radius: 6px;
        border: 1px solid var(--color-border);
        background-color: var(--color-background);
      }
    }
  }
}
</style>
