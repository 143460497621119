
import { defineComponent, shallowRef } from 'vue'
import TheRankNumber from '@/modules/weTradeCup/components/TheRankNumber.vue'
import TheCupTabSwitch from '@/modules/weTradeCup/components/TheCupTabSwitch.vue'
import Line from '@/components/Line.vue'
import Pic from '@/components/Pic.vue'
import Nullable from '@/components/Nullable.vue'
import Holder from '@/provider/Holder.vue'
import { readLiveRank } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { liveRoomInfo } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'TheRankDialog',
  components: {
    Line,
    Pic,
    Holder,
    Nullable,
    TheRankNumber,
    TheCupTabSwitch,
  },
  setup () {
    const tabIndex = shallowRef(0)

    const curruntList = shallowRef()

    const mine = shallowRef({
      avatarPic: '',
      nickName: '',
      consumePoints: 0,
      rank: 0,
    })

    const handleChange = () => {
      tabIndex.value = Number(!tabIndex.value)
      getRankList()
    }

    const getRankList = () => {
      readLiveRank({
        roomid: liveRoomInfo.value.roomid,
        type: tabIndex.value,
      }).then(res => {
        curruntList.value = res.liveSendGiftRankInfoModels
        mine.value.avatarPic = res.avatarPic
        mine.value.nickName = res.nickName
        mine.value.consumePoints = res.consumePoints
        mine.value.rank = res.rank
      })
    }
    getRankList()

    return {
      mine,
      tabIndex,
      curruntList,
      handleChange,
      translate,
    }
  },
})
