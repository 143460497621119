
import { defineComponent, shallowRef, onBeforeUnmount, watchEffect } from 'vue'
import TheCodeSelector from '@/pages/liveForQuickPlay/components/quickPlayInLive/TheCodeSelector.vue'
import TheChart from '@/modules/fastTrade/components/TheChart.vue'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import Line from '@/components/Line.vue'
import TheLiveTradePanel from '@/pages/liveForQuickPlay/components/quickPlayInLive/TheLiveTradePanel.vue'

import {
  calcStops,
  dropUnusedOrderColor,
  generateProducts,
  updatePositionsCount,
} from '@/modules/fastTrade/fastTrade'
import { ProductSchema } from '@/modules/fastTrade/fastTrade.api'
import { FastOrderSchema, readFastPositions } from '@/modules/fastTrade/history.api'
import { TradeDirection } from '@/types'
import { emit, off, on } from 'essential/tools/event'
import { events } from '@/config'
import { refreshAccount } from '@/modules/app/app'
import * as R from 'ramda'
import { curruntCode, product, isExpand } from '@/pages/liveForQuickPlay/liveForQuickPlay'

export default defineComponent({
  name: 'TheQuickPlayInLive',
  components: {
    TheCodeSelector,
    TheChart,
    DemoAccountTip,
    Line,
    TheLiveTradePanel,
  },
  emits: ['close', 'change'],
  setup (props, ctx) {
    const positions = shallowRef<FastOrderSchema[]>([]) // 记录持仓
    const stops = shallowRef<{ profit: number, loss: number } | null>(null) // 止盈/止损范围
    const tradeType = shallowRef(TradeDirection.BUY) // 做单方向

    const onCreated = (order: FastOrderSchema) => {
      positions.value = positions.value.concat(order)
      // ctx.emit('close')
    }

    const onTypeChange = (_type: TradeDirection) => {
      tradeType.value = _type
    }

    // 更新当前品种的持仓单
    readFastPositions().then(resp => {
      positions.value = R.filter(R.propEq('contactCode', curruntCode.value), resp)
      // 去除多余的订单颜色信息
      dropUnusedOrderColor(R.pluck('orderId', resp))
    })

    stops.value = calcStops(product.value as ProductSchema)

    watchEffect(() => {
      updatePositionsCount(positions.value.length)
    })

    const onPositionStop = (orderId: number) => {
      positions.value = R.reject(R.propEq('orderId', orderId), positions.value)
      refreshAccount()
    }

    const onProductChange = (product: ProductSchema) => {
      ctx.emit('change', product.contract)
      emit(events.fastTradeProductChange, generateProducts(product))
      stops.value = calcStops(product)
    }

    on(events.quickClose, onPositionStop)

    onBeforeUnmount(() => {
      off(events.quickClose, onPositionStop)
      isExpand.value = false
    })

    return {
      curruntCode,
      stops,
      tradeType,
      positions,
      onCreated,
      onTypeChange,
      onProductChange,
    }
  },
})
