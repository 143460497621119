<template>
  <div class="selector df-middle px-4">
    <CurrentQuote :code="product && product.contract" #="{symbol}">
      <div class="icon-wrap p-4" @click="openSelect">
        <Icon name="toggle" class="f-xl" />
      </div>
      <RealTimeQuote #="{ price, mp, isRise, margin }" :symbol="symbol">
        <div class="flex-1" @click="handleExpand">
          <span class="white f-bold">{{symbol && symbol.code || '--'}}</span>
          <span class="flex-1 c-success" :class="{'c-danger': !isRise}">
            <span class="ml-4">
              {{price}}
              <Icon
                class="success p-0"
                :class="{danger: !isRise}"
                :name="isRise ? 'up' : 'down'" sm
                style="vertical-align: top"
              />
            </span>
            <span class="ml-4">{{margin}}</span>
            <span class="ml-4">{{mp}}</span>
          </span>
        </div>
      </RealTimeQuote>
      <div class="icon-wrap p-4" @click="handleExpand">
        <Icon :name="isExpand ? 'caret-down' : 'caret-up'" class="f-xl" />
      </div>
    </CurrentQuote>
  </div>
</template>

<script lang="ts">
import { marketFeed } from '@/common/datafeed'
import Icon from '@/components/Icon.vue'
import { openDialog } from '@/components/popup/popup'
import { ProductSchema, readProducts } from '@/modules/fastTrade/fastTrade.api'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import * as R from 'ramda'
import { defineComponent, shallowRef, watch, onBeforeUnmount } from 'vue'
import TheQuickPlayCodes from '@/pages/liveForQuickPlay/components/quickPlayInLive/TheQuickPlayCodes.vue'

import { isAbleToPlay, isExpand, curruntCode, productList } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import TheQuickPlayInLive from '@/pages/liveForQuickPlay/components/TheQuickPlayInLive.vue'

export default defineComponent(
  {
    name: 'TheCodeSelector',
    components: { CurrentQuote, Icon, RealTimeQuote },
    props: {
      code: {
        type: String,
        required: true,
      },
    },
    emits: ['change', 'close'],
    setup (props, ctx) {
      const products = shallowRef<ProductSchema[]>([])
      const product = shallowRef<ProductSchema | null>(null)

      const watchInit = () => {
        if (curruntCode.value === '') {
          readProducts().then(resp => {
            productList.value = resp
            curruntCode.value = resp[0].contract
            marketFeed.subscribe([resp[0].contract])
            product.value = resp[0]
          })
        } else {
          product.value = R.find(R.propEq('contract', curruntCode.value), productList.value) || null
          marketFeed.subscribe([curruntCode.value])
        }
      }

      watch(() => curruntCode.value, () => {
        watchInit()
      }, {
        immediate: true,
      })

      readProducts().then(resp => {
        products.value = resp
        marketFeed.subscribe(R.pluck('contract', resp))
      })

      const openSelect = () => {
        if (!isAbleToPlay()) {
          return
        }
        openDialog(TheQuickPlayCodes, {
          wrapperClass: 'middle',
          onChange: (val: ProductSchema) => {
            ctx.emit('change', val)
            product.value = val
          },
        }, {
          transition: 'slide-down',
          touchToClose: true,
        })
      }

      const handleExpand = () => {
        if (!isAbleToPlay()) {
          return
        }
        isExpand.value = !isExpand.value
        if (isExpand.value) {
          openDialog(TheQuickPlayInLive, {}, {
            touchToClose: true,
          })
        } else {
          // close
          ctx.emit('close')
        }
      }

      onBeforeUnmount(() => {
        marketFeed.unSubscribe([], true)
      })

      return {
        products,
        product,
        isExpand,
        openSelect,
        handleExpand,
      }
    },
  },
)
</script>

<style scoped lang="scss">
.selector {
  min-height: 48px;
}

.panel {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.3);
}

::v-deep(.product) {
  height: 36px;
  line-height: 28px;
}
::v-deep(.choice) {
  padding: 0 !important;
}
.i-wrap{
  height: 100%;
}
.close-state{
  position: absolute;
  top: -1px;
  left: -1px;
  color: var(--color-white);
  background: var(--color-border);
  border-top-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 20px;
  padding: 0 4px;
}
</style>
