
import { TradeDirection, YesOrNo } from '@/types'
import Button from '@/components/Button.vue'
import { openDialog } from '@/components/popup/popup'
import useRequest from '@/hooks/useRequest'
import { translate } from '@/i18n'
import TheFiniteSelector from '@/modules/fastTrade/components/TheFiniteSelector.vue'
import { Product, generateProducts } from '@/modules/fastTrade/fastTrade'
import { create, ProductSchema } from '@/modules/fastTrade/fastTrade.api'
import { computed, defineComponent, shallowRef, watchEffect } from 'vue'
import { isDemoAccount } from '@/state/accountType'
import state from '@/state'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import * as R from 'ramda'

import TheCheckItem from '@/pages/setting/components/TheCheckItem.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import { product, liveSetting, checkLiveUsableCoupon } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import TheOrderConfirm from '@/pages/liveForQuickPlay/components/TheOrderConfirm.vue'
import { useRoute } from 'vue-router'
import { getPointAalert, GetPointAlertEnum } from '@/pages/liveForQuickPlay/components/liveAlert/liveAlert'
import router from '@/router'
import { refreshAccount } from '@/modules/app/app'
import {
  checkLimitEvent,
  LimitEventType,
} from '@/pages/liveForQuickPlay/liveForQuickPlay.api'

export default defineComponent({
  name: 'TheLiveTradePanel',
  components: {
    TheFiniteSelector,
    AccountRelated,
    Button,
    TheCheckItem,
  },
  emits: ['created', 'typeChanged'],
  setup (props, ctx) {
    const type = shallowRef(TradeDirection.BUY)
    const products = shallowRef<Product[]>([])
    const useCoupon = shallowRef(false)
    const useCash = shallowRef(true)
    const usableCouponCount = shallowRef(0)
    const couponPrice = shallowRef(0)
    const cashUsable = shallowRef(false)

    const route = useRoute()

    const curruntProduct = shallowRef<Product>()
    const isPrime = computed(() => state.prime.member === YesOrNo.YES)
    const isBuy = computed(() => type.value === TradeDirection.BUY)

    const free = computed(() => R.min(state.account.balance, state.account.freeMargin))

    products.value = generateProducts(product.value as ProductSchema)

    watchEffect(() => {
      ctx.emit('typeChanged', type.value)
    })

    const [_commit, progress] = useRequest(create)

    const commit = () => {
      if (!useCoupon.value && !useCash.value) {
        return
      }

      if (liveSetting.value.orderConfirmation) {
        openDialog(TheOrderConfirm, {
          order: {
            fee: curruntProduct.value?.fee,
            direction: type.value,
            invest: curruntProduct.value?.price,
            useCoupon: useCoupon.value,
            stopProfitOffset: curruntProduct.value?.stopProfitOffset,
            stopLossOffset: curruntProduct.value?.stopLossOffset,
            ...product.value,
          },
          onOk: () => {
            createRequest()
          },
        })
      } else {
        createRequest()
      }
    }

    const createRequest = () => {
      const params = {
        code: curruntProduct.value?.code,
        price: curruntProduct.value?.price,
        voucher: Number(useCoupon.value),
        type: type.value,
        roomId: route.params.chatRoomId,
      }

      if (!isDemoAccount.value && !isPrime.value) {
        openDialog(LimitDialogTemplate, {
          i18nPath: 'quickplay_52',
        })
        return false
      } else {
        return _commit(params).then(resp => {
          const pointsDesc = resp.rewardPoints || 0
          getPointAalert(GetPointAlertEnum.DEFAULT, {
            title: translate('quickplaylive_15', 'The order has been placed'),
            point: pointsDesc,
          })
          checkCoupon()
          refreshAccount()
          ctx.emit('created', resp)
        })
      }
    }

    const handleUseCash = () => {
      useCoupon.value = false
      useCash.value = true
    }

    const handleUseCoupon = () => {
      useCash.value = false
      useCoupon.value = true
    }

    const handleFiniteChange = (v: Product) => {
      curruntProduct.value = v
      if (free.value < Number(v.price)) {
        useCash.value = false
        cashUsable.value = false
      } else {
        cashUsable.value = true
      }
      couponPrice.value = Number(v.price)
      checkCoupon()
    }

    const checkCoupon = () => {
      checkLiveUsableCoupon().then(resp => {
        usableCouponCount.value = R.length(R.filter(R.propEq('price', Number(curruntProduct.value?.price)), resp))
        if (!usableCouponCount.value) {
          useCoupon.value = false
        }
      })
    }

    const pageToIn = () => {
      router.back()

      checkLimitEvent({
        roomid: route.params.chatRoomId,
      }).then(res => {
        console.log(res)
        if (res.length === 0) {
          router.push('/in?roomid=' + route.params.chatRoomId)
          return
        }
        res.forEach(item => {
          if (item.type === LimitEventType.DEPOSIT) {
            if (item.times > 0) {
              router.push('/in?roomid=' + route.params.chatRoomId + '&during=true')
            } else {
              router.push('/in?roomid=' + route.params.chatRoomId)
            }
          }
        })
      })
    }

    return {
      isBuy,
      useCoupon,
      useCash,
      usableCouponCount,
      couponPrice,
      cashUsable,
      progress,
      curruntProduct,
      products,
      TradeDirection,
      type,
      free,
      commit,
      handleUseCash,
      handleUseCoupon,
      handleFiniteChange,
      pageToIn,
    }
  },
})
