<template>
<div>
  <div class="top-msg df-between c-white px-12 pt-12">
    <div class="p-num df-middle">
      <div class="back-btn df-middle df-center mr-8" @click="handleBack">
        <Icon name="caret-left-lg" sm />
      </div>
      <Icon name="person" sm />
      <span class="ml-4 mr-16" style="margin-top: 1px;">{{ personNumber }}</span>

      <Pic src="live/live_like"  width="16" height="16" />
      <span class="ml-4" style="margin-top: 1px;">{{ likeNumber }}</span>
    </div>
    <div class="df-middle">
      <TheRankPreview />
    </div>
  </div>

  <div class="d-t-wrap px-12 mt-16">
    <div class="d-task df-middle px-8" :class="{'red-icon': hasUndoneTask}" @click="handleOpenTask">
      <Pic src="live/daily_task"  width="16" height="16" />
      <t path="quickplaylive_52" class="c-white f-md ml-4">Daily Task</t>
    </div>
  </div>

  <div class="d-t-wrap px-12 mt-16">
    <Transition name="timelable">
      <div class="time-task c-white" @click="handleOpenInsurence" v-if="tradeTime.show">
        <Pic src="live/insurence" class="p-a" style="z-index: 0;"  width="120" height="64" />
        <t path="" class="tt-title p-a">Trade with Insurance</t>
        <span class="tt-time p-a">{{tradeTime.hh}}:{{tradeTime.mm}}:{{tradeTime.ss}}</span>
      </div>
    </Transition>
  </div>

  <div class="d-t-wrap px-12 mt-16">
    <Transition name="timelable">
      <div class="time-task c-white" @click="pageToIn" v-if="depositTime.show">
        <Pic src="live/massive" class="p-a" style="z-index: 0;"  width="120" height="64" />
        <t path="" class="tt-title p-a">Massive Points</t>
        <span class="tt-time p-a">{{depositTime.hh}}:{{depositTime.mm}}:{{depositTime.ss}}</span>
      </div>
    </Transition>
  </div>
</div>
</template>

<script lang='ts'>
import { defineComponent, onBeforeUnmount, reactive, shallowRef } from 'vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import router from '@/router'
import TheRankPreview from '@/pages/liveForQuickPlay/components/TheRankPreview.vue'
import TheLiveTask from '@/pages/liveForQuickPlay/components/TheLiveTask.vue'
import TheInsurence from '@/pages/liveForQuickPlay/components/TheInsurence.vue'
import { openDialog } from '@/components/popup/popup'
import { handleTime, liveRoomInfo } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { readLiveTask } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { off, on } from 'essential/tools/event'
import { events } from '@/config'

export default defineComponent({
  name: 'TheLiveHead',
  components: {
    Icon,
    Pic,
    TheRankPreview,
  },
  props: {
    personNumber: Number,
    likeNumber: Number,
  },
  setup () {
    const hasUndoneTask = shallowRef(false)
    const tradeTime = reactive({
      hh: '00',
      mm: '00',
      ss: '00',
      leftTime: 0,
      timer: 0,
      show: false,
    })

    const depositTime = reactive({
      hh: '00',
      mm: '00',
      ss: '00',
      leftTime: 0,
      timer: 0,
      show: false,
    })

    const handleBack = () => {
      router.back()
    }

    const handleOpenTask = () => {
      hasUndoneTask.value = false
      openDialog(TheLiveTask, null, {
        touchToClose: true,
      })
    }

    const handleOpenInsurence = () => {
      openDialog(TheInsurence, {
        left: tradeTime.leftTime,
      }, {
        touchToClose: true,
      })
    }

    const tradeTimeLoop = () => {
      tradeTime.hh = handleTime(tradeTime.leftTime).hh
      tradeTime.mm = handleTime(tradeTime.leftTime).mm
      tradeTime.ss = handleTime(tradeTime.leftTime).ss
      tradeTime.show = true
      tradeTime.timer = window.setInterval(() => {
        tradeTime.hh = handleTime(tradeTime.leftTime).hh
        tradeTime.mm = handleTime(tradeTime.leftTime).mm
        tradeTime.ss = handleTime(tradeTime.leftTime).ss
        tradeTime.leftTime--
        if (tradeTime.leftTime < 0) {
          clearInterval(tradeTime.timer)
          tradeTime.show = false
          tradeTime.timer = 0
          return false
        }
      }, 1000)
    }

    const depositLoop = () => {
      depositTime.hh = handleTime(depositTime.leftTime).hh
      depositTime.mm = handleTime(depositTime.leftTime).mm
      depositTime.ss = handleTime(depositTime.leftTime).ss
      depositTime.show = true
      depositTime.timer = window.setInterval(() => {
        depositTime.hh = handleTime(depositTime.leftTime).hh
        depositTime.mm = handleTime(depositTime.leftTime).mm
        depositTime.ss = handleTime(depositTime.leftTime).ss
        depositTime.leftTime--
        if (depositTime.leftTime < 0) {
          clearInterval(depositTime.timer)
          depositTime.show = false
          depositTime.timer = 0
          return false
        }
      }, 1000)
    }

    // depositLoop()

    readLiveTask().then(res => {
      for (let i = 0; i < res.tasks.length; i++) {
        if (res.tasks[i].isCompleted === 0) {
          hasUndoneTask.value = true
          break
        }
      }
    })

    on(events.liveDepositAct, (res) => {
      console.log('deposit', res)
      if (depositTime.show) {
        if (Number(res) === 0) {
          depositTime.show = false
        } else {
          return
        }
      }
      depositTime.leftTime = Number(res || 0)
      if (depositTime.leftTime > 0) {
        depositLoop()
      }
    })

    on(events.liveTradeAct, (res) => {
      console.log('trade', res)
      if (tradeTime.show) {
        if (Number(res) === 0) {
          tradeTime.show = false
        } else {
          return
        }
      }
      tradeTime.leftTime = Number(res || 0)
      if (tradeTime.leftTime > 0) {
        tradeTimeLoop()
      }
    })

    onBeforeUnmount(() => {
      clearInterval(tradeTime.timer)
      off(events.liveDepositAct)
      off(events.liveTradeAct)
    })

    const pageToIn = () => {
      router.push('/in?roomid=' + liveRoomInfo.value.roomid + '&during=true')
    }

    return {
      hasUndoneTask,
      tradeTime,
      depositTime,
      handleBack,
      handleOpenTask,
      handleOpenInsurence,
      pageToIn,
    }
  },
})
</script>

<style>
@keyframes timelable-in {
  0% {
    margin-right: -200px;
  }
  100% {
    margin-right: 0;
  }
}

@keyframes timelable-out {
  0% {
    margin-right: 0;
  }
  100% {
    margin-right: -200px;
  }
}
</style>

<style scoped lang='scss'>
.top-msg{
  .back-btn{
    height: 36px;
    width: 36px;
    background-color: rgba(24, 29, 36, 0.4);
    border-radius: 50%;
  }
}
.d-t-wrap{
  display: flex;
  flex-direction: row-reverse;
  z-index: 1;
  .d-task{
    background-color: rgba(24, 29, 36, 0.4);
    height: 26px;
    border-radius: 13px;
    position: relative;
    &.red-icon{
      &::after {
        top: -2px;
        right: 2px;
        position: absolute;
        display: inline-block;
        width: 8px;
        height: 8px;
        background: var(--color-danger);
        border-radius: 8px;
        content: '';
      }
    }
  }
}

.time-task{
  width: 120px;
  height: 64px;
  position: relative;
  .tt-title{
    top: 16px;
    left: 10px;
    width: 68px;
    line-height: 12px;
    font-size: 13px;
  }
  .tt-time{
    font-size: 14px;
    bottom: 2px;
    left: 28px;
  }
}

.timelable-enter-active {
  animation: timelable-in 0.3s;
}
.timelable-leave-active {
  animation: timelable-out 0.3s;
}
</style>
