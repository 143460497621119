
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheLikeDialog',
  emits: ['cancel', 'ok', 'close'],
  components: { DialogTemplate, Pic },
  setup (props, ctx) {
    const handleCancel = () => {
      ctx.emit('cancel')
      ctx.emit('close')
    }

    const handleOk = () => {
      ctx.emit('ok')
      ctx.emit('close')
    }

    return {
      handleCancel,
      handleOk,
    }
  },
})
