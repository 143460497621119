<template>
  <div class="df-middle" @click="openRankList">
    <div class="rank-list">
      <div class="rank-item" v-for="(item, index) in rankList" :key="index"
        :style="`right: ${index * 32 * 0.75 + 8}px; z-index: ${index}`"
      >
        <Pic :src="(item && item.avatarPic) || ''" fallback="/img/user/Avatar@2x.png" width="28" height="28" />
      </div>
    </div>
    <Icon name="caret-right" sm />
  </div>
</template>

<script lang='ts'>
import { defineComponent, onUnmounted, shallowRef } from 'vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { openDialog } from '@/components/popup/popup'
import TheRankDialog from '@/pages/liveForQuickPlay/components/TheRankDialog.vue'
import { readLiveRank, RankType } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheRankPreview',
  components: {
    Icon,
    Pic,
  },
  setup () {
    const rankList = shallowRef()
    const route = useRoute()

    const getList = () => {
      readLiveRank({
        roomid: route.params.chatRoomId,
        type: RankType.TODAY,
      }).then(res => {
        rankList.value = res.liveSendGiftRankInfoModels || []
        rankList.value.length = 5
        rankList.value = rankList.value.reverse()
      })
    }
    getList()

    let timer = 0
    timer = window.setInterval(() => {
      getList()
    }, 1000 * 60)

    const openRankList = () => {
      openDialog(TheRankDialog, null, {
        touchToClose: true,
      })
    }

    onUnmounted(() => {
      clearInterval(timer)
    })

    return {
      rankList,
      openRankList,
    }
  },
})
</script>

<style scoped lang='scss'>
.rank-list{
  position: relative;
  height: 32px;
}
.rank-item{
  position: absolute;
  height: 32px;
  width: 32px;
  border: 2px solid var(--color-white);
  border-radius: 50%;
  top: 0;
  overflow: hidden;
}
</style>
