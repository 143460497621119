<template>
  <div class="fill live-end" :style="`background-image: url(${prop('backgroundPicture', liveInfo)});`">
    <div class="fill df-col c-white df-middle" style="background: rgba(0, 0, 0, 0.6);">
      <div class="e-top" style="width: 100%;">
        <div class="top-msg c-white px-12 pt-12">
          <div class="p-num df-middle">
            <div class="back-btn df-middle" @click="handleBack">
              <Icon name="caret-left-lg" sm />
            </div>
          </div>
        </div>
      </div>
      <div class="a-icon mt-24">
        <Pic width="82" height="82" :src="prop('authorUrl', liveInfo)" />
      </div>
      <p class="f-bold f-xxl mt-24">{{ prop('authorName', liveInfo) }}</p>
      <t path="quickplaylive_48" as="p" class="f-xl l-state df-middle px-32 mt-32">
        Live Stream Ending
      </t>
      <div class="sum df-between mt-32">
        <div class="sum-item">
          <div class="df-middle">
            <Pic class="mr-8" src="live/audience" width="24" height="24" />
            <span style="font-size: 27px;">{{ prop('onlineNumber', liveInfo) }}</span>
          </div>
          <t class="f-xl mt-8" as="p" path="quickplaylive_49">Audience</t>
        </div>
        <div class="sum-item">
          <div class="df-middle">
            <Pic class="mr-8" src="live/live_like" width="24" height="24" />
            <span style="font-size: 27px;">{{ prop('like', liveInfo) }}</span>
          </div>
          <t class="f-xl mt-8" as="p" path="quickplaylive_50">Likes</t>
        </div>
      </div>

      <div class="px-32" style="margin-top: 80px;">
        <t as="p" path="quickplaylive_51"></t>
        <t path="" as="p" style="line-height: 24px;" class="mt-12">
          {{prop('channelDescribe', liveInfo)}}
        </t>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'
import { readLiveRoom } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { useRoute } from 'vue-router'
import router from '@/router'

export default defineComponent({
  name: 'TheLiveEnd',
  components: {
    Pic,
    Icon,
  },
  setup () {
    const route = useRoute()
    const liveInfo = shallowRef()

    const handleBack = () => {
      router.back()
    }

    readLiveRoom({
      channelId: route.params.channelId,
    }).then((res) => {
      liveInfo.value = res
    })
    return {
      liveInfo,
      handleBack,
    }
  },
})
</script>

<style scoped lang='scss'>
.live-end{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .a-icon{
    height: 88px;
    width: 88px;
    border-radius: 50%;
    border: 3px solid var(--color-white);
    overflow: hidden;
  }
  .l-state{
    height: 41px;
    border-radius: 20px;
    border: 1px solid var(--color-white);
  }
  .sum{
    width: 100%;
    .sum-item{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
