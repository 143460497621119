
import { openDialog } from '@/components/popup/popup'
import { defineComponent, onMounted, onUnmounted, shallowRef } from 'vue'
import TheQuickPlayInLive from '@/pages/liveForQuickPlay/components/TheQuickPlayInLive.vue'
import TheCodeSelector from '@/pages/liveForQuickPlay/components/quickPlayInLive/TheCodeSelector.vue'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'

import { isAbleToPlay, isExpand } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { readMaxProfit } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheBottomView',
  components: {
    TheCodeSelector,
    Pic,
    Icon,
  },
  setup () {
    const view = shallowRef('quote')
    const topName = shallowRef('')
    const topEarn = shallowRef(0)
    const route = useRoute()

    let timer = 0

    const interval = [1000 * 60 * 5, 1000 * 5]
    // const interval = [1000 * 10, 1000 * 2]

    const curruntCode = shallowRef('')

    enum ViewIndex {
      QUOTE,
      RANK,
    }

    let intervalIndex = ViewIndex.QUOTE

    onMounted(() => {
      intervalLoop()
    })

    const intervalLoop = () => {
      if (intervalIndex === ViewIndex.QUOTE) {
        view.value = 'quote'
        clearTimeout(timer)
        timer = window.setTimeout(() => {
          intervalIndex = ViewIndex.RANK
          readMaxProfit({
            roomid: route.params.chatRoomId,
          }).then((res) => {
            topName.value = (res.userName || '').substr(0, 3) + '******'
            topEarn.value = res.amount || 0
            view.value = 'rank'
          }).finally(() => {
            intervalLoop()
          })
        }, interval[intervalIndex])
      } else {
        clearTimeout(timer)
        timer = window.setTimeout(() => {
          view.value = 'quote'
          intervalIndex = ViewIndex.QUOTE
          intervalLoop()
        }, interval[intervalIndex])
      }
    }

    onUnmounted(() => {
      clearInterval(timer)
    })

    const handleExpand = () => {
      if (!isAbleToPlay()) {
        return
      }
      isExpand.value = !isExpand.value
      if (isExpand.value) {
        openDialog(TheQuickPlayInLive, {}, {
          touchToClose: true,
        })
      }
    }

    return {
      view,
      topName,
      topEarn,
      curruntCode,
      isExpand,
      handleExpand,
    }
  },
})
