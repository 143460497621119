
import ChoiceButton from '@/components/ChoiceButton.vue'
import { ProductSchema } from '@/modules/fastTrade/fastTrade.api'
import Matrix from 'common/Matrix.vue'
import * as R from 'ramda'
import { defineComponent, shallowRef, toRaw, watch } from 'vue'
import { curruntCode, productList } from '@/pages/liveForQuickPlay/liveForQuickPlay'

export default defineComponent(
  {
    name: 'TheCodeSelector',
    components: { ChoiceButton, Matrix },
    props: {
      type: Number,
    },
    emits: ['change', 'close'],
    setup (props, ctx) {
      const product = shallowRef<ProductSchema | null>(null)

      product.value = R.find(
        R.propEq('contract', curruntCode.value), productList.value,
      ) ?? productList.value[0]

      watch(() => product.value, () => {
        if (product.value) {
          ctx.emit('change', toRaw(product.value))
          curruntCode.value = toRaw(product.value).contract
        }
      })

      const handleChose = (item: ProductSchema) => {
        product.value = item
        ctx.emit('close')
      }

      return {
        product,
        productList,
        curruntCode,
        handleChose,
      }
    },
  },
)
