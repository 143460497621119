
import { defineComponent, shallowRef } from 'vue'
import { leftPoints } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import Line from '@/components/Line.vue'
import Icon from '@/components/Icon.vue'

import { readLiveTask, LiveTaskItem } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'

export default defineComponent({
  name: 'TheLiveTask',
  components: {
    Line,
    Icon,
  },
  setup () {
    const persent = shallowRef(0)
    const taskPoints = shallowRef(0)
    const completedPoints = shallowRef(0)

    const completed = shallowRef<LiveTaskItem[]>([])
    const unCompleted = shallowRef<LiveTaskItem[]>([])

    readLiveTask().then(res => {
      res.tasks.map((item) => {
        if (item.isCompleted === 1) {
          completed.value.push(item)
        }
        if (item.isCompleted === 0) {
          unCompleted.value.push(item)
        }

        taskPoints.value = res.taskPoints
        completedPoints.value = res.completedPoints
        persent.value = (completedPoints.value / taskPoints.value) * 100
        persent.value = persent.value > 100 ? 100 : persent.value
      })
    })

    return {
      persent,
      taskPoints,
      completedPoints,
      completed,
      unCompleted,
      leftPoints,
    }
  },
})
