<template>
<DialogTemplate>
  <template #title>
    <t path="quickplaylive_27">Order Confirmation</t>
  </template>

  <div class="c-wrap fill py-24">
    <CurrentQuote :code="prop('contract', order)" #="{symbol}">
      <RealTimeQuote :symbol="symbol" #="{price}">

        <div class="d-f fill">
          <div class="flex-1">
            <p class="mb-8">
              <t path="quickplaylive_28" class="mr-8 c-title">Symbol</t>
              <span class="">{{ prop('contract', order) }}</span>
            </p>
            <p class="mb-8">
              <t path="quickplaylive_30" class="mr-8 c-title">Invest</t>
              <money :v="prop('invest', order)" />
              <Icon name="coupon" sm v-if="prop('useCoupon', order)" />
            </p>
            <p class="mb-8">
              <t path="quickplaylive_32" class="mr-8 c-title">S/L</t>
              <span class="c-danger">
                -<money :v="prop('invest', order)" /> @{{calcStopPrice(price)}}
              </span>
            </p>
          </div>
          <div class="flex-1">
            <p class="mb-8">
              <t path="quickplaylive_29" class="mr-8 c-title">Direction</t>
              <t custom #="{td, t}">
                <ColorText class="f-medium" :display="[t('symbol_10') || 'Sell', t('symbol_9') || 'Buy']"
                  :value="prop('direction', order) - 2" />
              </t>
            </p>
            <p class="mb-8">
              <t path="quickplaylive_31" class="mr-8 c-title">T/P</t>
              <span class="c-success">
                +<money :v="prop('invest', order)" /> @{{calcStopPrice(price, true)}}
              </span>
            </p>
          </div>
        </div>

      </RealTimeQuote>
    </CurrentQuote>

    <p class="mb-8 mt-12">
      <t path="quickplaylive_33" class="mr-8 c-title">Commission</t>
      <money :v="prop('fee', order)" />
    </p>
    <p class="df-middle">
      <Icon name="warn" sm />
      <t path="quickplaylive_34" class="c-tip ml-8 f-md">Only chatred when profit is made</t>
    </p>

    <div class="c-switch d-f mt-32" @click="checked = !checked">
      <div class="check-wrap br-sm mr-8 df-middle df-center">
        <Icon name="check" sm v-if="checked" />
      </div>
      <t path="quickplaylive_35">
        Turn off this dialogue before placing order. It can be truned on any time from settings
      </t>
    </div>
  </div>

  <template #controls>
    <button class="flex-1 btn block" @click="handleCancel">
      <t path="quickplaylive_36" custom #="{td}">
        {{ td || 'Cancel' }}
      </t>
    </button>
    <button class="flex-1 btn block primary" @click="handleOk">
      <t path="quickplaylive_37" custom #="{td}">
        {{ td || 'Place Order' }}
      </t>
    </button>
  </template>
</DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'
import Icon from '@/components/Icon.vue'
import ColorText from '@/components/ColorText.vue'
import { saveSetting } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { liveSetting } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { add } from 'essential/tools/math'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'

export default defineComponent({
  name: 'TheOrderConfirm',
  emits: ['cancel', 'ok', 'close'],
  components: {
    DialogTemplate,
    Icon,
    ColorText,
    CurrentQuote,
    RealTimeQuote,
  },
  props: {
    order: Object,
  },
  setup (props, ctx) {
    const checked = shallowRef(false)

    const handleCancel = () => {
      ctx.emit('cancel')
      ctx.emit('close')
    }

    const handleOk = () => {
      if (checked.value) {
        liveSetting.value.orderConfirmation = 0
        saveSetting(liveSetting.value)
      }
      ctx.emit('ok')
      ctx.emit('close')
    }

    const calcStopPrice = (price: string, profit = false) => {
      const diff = profit ? props.order?.stopProfitOffset : -(props.order?.stopLossOffset ?? 0)
      return add(price, (diff || 0) * (props.order?.direction === 2 ? 1 : -1))
    }

    return {
      checked,

      handleCancel,
      handleOk,
      calcStopPrice,
    }
  },
})
</script>

<style scoped lang="scss">
.c-switch{
  display: flex;
  align-items: flex-start;
}
.check-wrap{
  background: var(--color-background);
  border: 1px solid var(--color-border);
  height: 16px;
  min-width: 16px;
  max-width: 16px;
}
</style>
