
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'
import { readLiveRoom } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { useRoute } from 'vue-router'
import router from '@/router'

export default defineComponent({
  name: 'TheLiveEnd',
  components: {
    Pic,
    Icon,
  },
  setup () {
    const route = useRoute()
    const liveInfo = shallowRef()

    const handleBack = () => {
      router.back()
    }

    readLiveRoom({
      channelId: route.params.channelId,
    }).then((res) => {
      liveInfo.value = res
    })
    return {
      liveInfo,
      handleBack,
    }
  },
})
