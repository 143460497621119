
import Button from '@/components/Button.vue'
import { refreshAccount } from '@/modules/app/app'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, ref, shallowRef, h, render } from 'vue'
import useRequest from '@/hooks/useRequest'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { leftPoints, liveRoomInfo, useIMsend, IMConfig } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { exchangeGift } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import TheGiftAnimation from '@/pages/liveForQuickPlay/components/liveAlert/TheGiftAnimation.vue'

export default defineComponent({
  name: 'TheGiftConfirmDialog',
  components: {
    Pic,
    Icon,
    Button,
    DialogTemplate,
  },
  emits: ['close'],
  props: {
    gift: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const numValue = ref(1)
    const [commit, progress] = useRequest(exchangeGift)
    const basePoint = props.gift.poins || 0
    const changePoints = shallowRef(props.gift.poins)
    const disabled = shallowRef(false)

    const change = (dir: number) => {
      if (dir < 0) {
        numValue.value = Number(numValue.value) - 1
      } else {
        if ((Number(numValue.value) + 1) * basePoint < leftPoints.value) {
          numValue.value = Number(numValue.value) + 1
        }
      }
      changePoints.value = basePoint * numValue.value
      disableCheck()
    }

    const disableCheck = () => {
      if ((Number(numValue.value) + 1) * basePoint < leftPoints.value) {
        disabled.value = false
      } else {
        disabled.value = true
      }
    }

    const submit = () => {
      // handleSendGift()
      commit({
        giftId: props.gift.giftId,
        giftNum: numValue.value,
        roomid: liveRoomInfo.value.roomid,
      }).then((res) => {
        if (res.number > 0) {
          handleSendGift()
        }
      }).finally(() => {
        refreshAccount()
        ctx.emit('close')
      })
    }

    const handleSendGift = () => {
      const params = {
        ...props.gift,
        count: numValue.value,
        nickname: IMConfig.value?.name,
        stamp: new Date().valueOf(),
      }

      render(h(TheGiftAnimation, {
        gift: params,
      }), document.body)

      const msg = 'gift#wt-msg#' + JSON.stringify(params)
      useIMsend(msg)
      ctx.emit('close')
    }

    const handleInput = () => {
      if (typeof Number(numValue.value) !== 'number') {
        numValue.value = 1
        return false
      }

      const maxExchange = Math.floor(leftPoints.value / basePoint)

      if (numValue.value > maxExchange || numValue.value > 20) {
        numValue.value = maxExchange <= 20 ? maxExchange : 20
      }

      changePoints.value = basePoint * numValue.value
    }

    return {
      progress,
      numValue,
      changePoints,
      leftPoints,
      submit,
      change,
      handleInput,
      disabled,
    }
  },
})
