
import { defineComponent, onBeforeUnmount, reactive, shallowRef } from 'vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import router from '@/router'
import TheRankPreview from '@/pages/liveForQuickPlay/components/TheRankPreview.vue'
import TheLiveTask from '@/pages/liveForQuickPlay/components/TheLiveTask.vue'
import TheInsurence from '@/pages/liveForQuickPlay/components/TheInsurence.vue'
import { openDialog } from '@/components/popup/popup'
import { handleTime, liveRoomInfo } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { readLiveTask } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { off, on } from 'essential/tools/event'
import { events } from '@/config'

export default defineComponent({
  name: 'TheLiveHead',
  components: {
    Icon,
    Pic,
    TheRankPreview,
  },
  props: {
    personNumber: Number,
    likeNumber: Number,
  },
  setup () {
    const hasUndoneTask = shallowRef(false)
    const tradeTime = reactive({
      hh: '00',
      mm: '00',
      ss: '00',
      leftTime: 0,
      timer: 0,
      show: false,
    })

    const depositTime = reactive({
      hh: '00',
      mm: '00',
      ss: '00',
      leftTime: 0,
      timer: 0,
      show: false,
    })

    const handleBack = () => {
      router.back()
    }

    const handleOpenTask = () => {
      hasUndoneTask.value = false
      openDialog(TheLiveTask, null, {
        touchToClose: true,
      })
    }

    const handleOpenInsurence = () => {
      openDialog(TheInsurence, {
        left: tradeTime.leftTime,
      }, {
        touchToClose: true,
      })
    }

    const tradeTimeLoop = () => {
      tradeTime.hh = handleTime(tradeTime.leftTime).hh
      tradeTime.mm = handleTime(tradeTime.leftTime).mm
      tradeTime.ss = handleTime(tradeTime.leftTime).ss
      tradeTime.show = true
      tradeTime.timer = window.setInterval(() => {
        tradeTime.hh = handleTime(tradeTime.leftTime).hh
        tradeTime.mm = handleTime(tradeTime.leftTime).mm
        tradeTime.ss = handleTime(tradeTime.leftTime).ss
        tradeTime.leftTime--
        if (tradeTime.leftTime < 0) {
          clearInterval(tradeTime.timer)
          tradeTime.show = false
          tradeTime.timer = 0
          return false
        }
      }, 1000)
    }

    const depositLoop = () => {
      depositTime.hh = handleTime(depositTime.leftTime).hh
      depositTime.mm = handleTime(depositTime.leftTime).mm
      depositTime.ss = handleTime(depositTime.leftTime).ss
      depositTime.show = true
      depositTime.timer = window.setInterval(() => {
        depositTime.hh = handleTime(depositTime.leftTime).hh
        depositTime.mm = handleTime(depositTime.leftTime).mm
        depositTime.ss = handleTime(depositTime.leftTime).ss
        depositTime.leftTime--
        if (depositTime.leftTime < 0) {
          clearInterval(depositTime.timer)
          depositTime.show = false
          depositTime.timer = 0
          return false
        }
      }, 1000)
    }

    // depositLoop()

    readLiveTask().then(res => {
      for (let i = 0; i < res.tasks.length; i++) {
        if (res.tasks[i].isCompleted === 0) {
          hasUndoneTask.value = true
          break
        }
      }
    })

    on(events.liveDepositAct, (res) => {
      console.log('deposit', res)
      if (depositTime.show) {
        if (Number(res) === 0) {
          depositTime.show = false
        } else {
          return
        }
      }
      depositTime.leftTime = Number(res || 0)
      if (depositTime.leftTime > 0) {
        depositLoop()
      }
    })

    on(events.liveTradeAct, (res) => {
      console.log('trade', res)
      if (tradeTime.show) {
        if (Number(res) === 0) {
          tradeTime.show = false
        } else {
          return
        }
      }
      tradeTime.leftTime = Number(res || 0)
      if (tradeTime.leftTime > 0) {
        tradeTimeLoop()
      }
    })

    onBeforeUnmount(() => {
      clearInterval(tradeTime.timer)
      off(events.liveDepositAct)
      off(events.liveTradeAct)
    })

    const pageToIn = () => {
      router.push('/in?roomid=' + liveRoomInfo.value.roomid + '&during=true')
    }

    return {
      hasUndoneTask,
      tradeTime,
      depositTime,
      handleBack,
      handleOpenTask,
      handleOpenInsurence,
      pageToIn,
    }
  },
})
