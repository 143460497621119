import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import { isDemoAccount } from '@/state/accountType'
import state from '@/state'
import { YesOrNo } from '@/types'
import { computed, shallowRef } from 'vue'
import { openDialog } from '@/components/popup/popup'
import { ProductSchema } from '@/modules/fastTrade/fastTrade.api'
import * as R from 'ramda'
import {
  ChatConfig,
  sendChatMsg,
  readChatConfig,
  readChatState,
} from '@/modules/membership/member.api'
import { readLiveVoucherList, VoucherStatus } from '@/pages/coupons/coupons.api'
import TheAddChatNickNameDialog from '@/modules/membership/components/TheAddChatNickNameDialog.vue'

const isPrime = computed(() => state.prime.member === YesOrNo.YES)

const CODES = {
  LACK_OF_NICKNAME: '400002',
}

export const isAbleToPlay = (hideDialog?: boolean) => {
  if (!isDemoAccount.value && !isPrime.value) {
    if (!hideDialog) {
      openDialog(LimitDialogTemplate, {
        i18nPath: 'quickplaylive_18',
      })
    }
    return false
  }
  return true
}

export const liveRoomInfo = shallowRef({
  roomid: '',
  channelId: '',
})

export const isExpand = shallowRef(false)

export const curruntCode = shallowRef<string>('')

export const productList = shallowRef<ProductSchema[]>([])

export const product = computed(() => R.find(R.propEq('contract', curruntCode.value), productList.value))

export const levelModel = computed(() => state.account.levelModel)
export const leftPoints = computed(() => levelModel.value.validPoints)

export const liveSetting = shallowRef({
  name: '',
  orderConfirmation: 0,
  blackgroundPlayback: 0,
  hideLikesAndGifts: 0,
})

export const IMConfig = shallowRef<ChatConfig | null>(null)

export const useIMsend = (text: string, cb?: () => void) => {
  const params = {
    roomid: IMConfig.value?.roomId,
    fromAccid: IMConfig.value?.accId,
    msgType: 0,
    body: text,
  }

  sendChatMsg(params).then().finally(() => {
    cb?.()
  }).catch(e => {
    if (e.c === CODES.LACK_OF_NICKNAME) {
      openDialog(TheAddChatNickNameDialog, {
        onCancel: refreshIMconfig,
        onConfirm: refreshIMconfig,
      })
    }
  })
}

export const refreshIMconfig = (roomid: number | string) => {
  readChatConfig({
    roomId: roomid || liveRoomInfo.value.roomid,
  }).then(resp => {
    readChatState({
      roomId: roomid,
    }).then(state => {
      IMConfig.value = {
        ...resp,
        readonly: Boolean(!state),
      }
    })
  }).catch(e => {
    if (e.c === CODES.LACK_OF_NICKNAME) {
      openDialog(TheAddChatNickNameDialog, {
        onCancel: refreshIMconfig,
        onConfirm: refreshIMconfig,
      })
    }
  })
}

export const checkLiveUsableCoupon = () => {
  return readLiveVoucherList({
    type: 1,
    status: VoucherStatus.USABLE,
    page: 1,
    pageCount: 1000,
  })
}

const format = (num: number) => {
  return num > 9 ? num.toString() : '0' + num
}
/**
 * 根据秒数计算 小时：分钟：秒
 * 用于计算倒计时
 */
export const handleTime = (time: number) => {
  const h = Math.floor(time / (60 * 60))
  const m = Math.floor((time % (60 * 60)) / 60)
  const s = (time % (60 * 60)) % 60

  return {
    hh: format(h),
    mm: format(m),
    ss: format(s),
  }
}

/**
 * 自定义消息格式***：{type}#wt-msg#{content}
 * 例： deposit#wt-msg#60  限时充值活动 剩余时间60s
 *      delete#wt-msg#msgid  删除id为{msgid}的消息（先发后删）
 */
export enum MsgType {
  GIFT = 'gift',
  LIMITED_TIME_DEPOSIT = 'deposit',
  LIMITED_TIME_TRADE = 'trade',
  DELETE_MSG = 'delete',
}
