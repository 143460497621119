<template>
  <Page>
    <div class="main-body df-col">
      <div v-if="pageLoading" class="l-loading flex-1">
        <Loading class="c-white"/>
      </div>
      <div v-else class="flex-1" style="overflow: hidden;">
        <TheLiveEnd v-if="streamAddress === ''" />
        <TheLivePlayer v-else :address="streamAddress" />
      </div>
      <TheBottomView />
    </div>
    <div class="chat-body df-col" v-if="streamAddress && !pageLoading">
      <TheLiveHead :likeNumber="likeNumber" :personNumber="personNumber" />
      <div class="placeholder flex-1"></div>
      <TheChatContent class="mb-24">
        <TheGiftContent class="mb-12" v-if="!liveSetting.hideLikesAndGifts" />
      </TheChatContent>
      <TheMessages />
    </div>
  </Page>
</template>

<script lang='ts'>
import Page from '@/provider/Page.vue'
import TheMessages from '@/pages/liveForQuickPlay/components/TheMessages.vue'
import TheLivePlayer from '@/pages/liveForQuickPlay/components/TheLivePlayer.vue'
import TheChatContent from '@/pages/liveForQuickPlay/components/TheChatContent.vue'
import TheBottomView from '@/pages/liveForQuickPlay/components/TheBottomView.vue'
import TheLiveEnd from '@/pages/liveForQuickPlay/components/TheLiveEnd.vue'
import TheGiftContent from '@/pages/liveForQuickPlay/components/TheGiftContent.vue'
import TheLiveHead from '@/pages/liveForQuickPlay/components/TheLiveHead.vue'

import Loading from 'common/loading/Donut.vue'

import { defineComponent, nextTick, onMounted, onUnmounted, shallowRef } from 'vue'
import { openDialog } from '@/components/popup/popup'
import { keymap, events } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import TheLiveGuideDialog from '@/pages/liveForQuickPlay/components/TheLiveGuideDialog.vue'
import { liveSetting, refreshIMconfig, liveRoomInfo } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import {
  updateWatchTime,
  readLiveAdd,
  readSetting,
  checkLimitEvent,
  LimitEventType,
} from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { useRoute } from 'vue-router'
import { emit } from 'essential/tools/event'

export default defineComponent({
  name: 'LiveForQuickPlay',
  components: {
    Page,
    TheMessages,
    TheLivePlayer,
    TheChatContent,
    TheBottomView,
    TheLiveEnd,
    Loading,
    TheGiftContent,
    TheLiveHead,
  },
  setup () {
    const route = useRoute()
    const pageLoading = shallowRef(true)
    const streamAddress = shallowRef('')
    const likeNumber = shallowRef(0)
    const personNumber = shallowRef(0)

    liveRoomInfo.value.roomid = route.params.chatRoomId as string
    liveRoomInfo.value.channelId = route.params.channelId as string

    readLiveAdd({
      channelId: route.params.channelId,
    }).then((res) => {
      streamAddress.value = res.hlsDownstreamAddress || ''
      // streamAddress.value = '12138'
      pageLoading.value = false
    })

    let timer = 0
    let params:{
      roomid: string;
      startFlag?: number | undefined;
    } = {
      roomid: route.params.chatRoomId as string,
      startFlag: 0,
    }

    const showGuide = () => {
      openDialog(TheLiveGuideDialog, { wrapperClass: 'center' }, { transition: 'slide-down' })
    }

    // 更新观看直播时间
    const updateTime = () => {
      timer = window.setInterval(() => {
        updateWatchTime(params, { silent: true }).then((res) => {
          likeNumber.value = res.like
          personNumber.value = res.numberOfPeople
          params = {
            roomid: route.params.chatRoomId as string,
          }
        })
      }, 1000 * 5)
    }

    readSetting().then(resp => {
      liveSetting.value = resp
    })

    onMounted(() => {
      updateTime()
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    // 获取聊天室配置信息
    refreshIMconfig(route.params.chatRoomId as string)

    if (!localGet(keymap.guide.liveGuide)) {
      showGuide()
      localSet(keymap.guide.liveGuide, '1')
    }

    nextTick(() => {
      checkLimitEvent({
        roomid: route.params.chatRoomId,
      }).then(res => {
        res.forEach(item => {
          if (item.times > 0) {
            if (item.type === LimitEventType.DEPOSIT) {
              emit(events.liveDepositAct, item.times)
            } else if (item.type === LimitEventType.TRADE) {
              emit(events.liveTradeAct, item.times)
            }
          }
        })
      })
    })

    return {
      streamAddress,
      likeNumber,
      personNumber,
      pageLoading,
      liveSetting,
    }
  },
})
</script>

<style scoped lang='scss'>
.main-body{
  z-index: 0;
  height: 100vh;
  width: 100vw;
  background-color: #777777;
}

.chat-body{
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 78px;
}
.l-loading{
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
}
</style>
