
import { defineComponent, onUnmounted, reactive, watch } from 'vue'
import Line from '@/components/Line.vue'
import { handleTime } from '@/pages/liveForQuickPlay/liveForQuickPlay'

export default defineComponent({
  name: 'TheInsurence',
  components: {
    Line,
  },
  props: {
    left: Number,
  },
  setup (props) {
    const time = reactive({
      hh: '00',
      mm: '00',
      ss: '00',
      leftTime: 0,
      timer: 0,
    })

    const timeLoop = () => {
      time.hh = handleTime(time.leftTime).hh
      time.mm = handleTime(time.leftTime).mm
      time.ss = handleTime(time.leftTime).ss
      time.timer = window.setInterval(() => {
        time.leftTime--
        if (time.leftTime === 0) {
          clearInterval(time.timer)
          return false
        }
        time.hh = handleTime(time.leftTime).hh
        time.mm = handleTime(time.leftTime).mm
        time.ss = handleTime(time.leftTime).ss
      }, 1000)
    }

    watch(() => props.left, () => {
      time.leftTime = props.left as number
      timeLoop()
    }, {
      immediate: true,
    })

    onUnmounted(() => {
      clearInterval(time.timer)
    })

    return {
      time,
    }
  },
})
