
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import * as R from 'ramda'
import { readGiftList, GiftItem } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import HoldOn from '@/provider/HoldOn.vue'
import { leftPoints, isAbleToPlay } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { noPointAlert } from '@/pages/liveForQuickPlay/components/liveAlert/liveAlert'
import TheGiftConfirmDialog from '@/pages/liveForQuickPlay/components/TheGiftConfirmDialog.vue'
import { openDialog } from '@/components/popup/popup'
import Swipe from '@/components/Swipe.vue'

export default defineComponent({
  name: 'TheGiftDialog',
  components: {
    Pic,
    HoldOn,
    Swipe,
  },
  emits: ['close'],
  setup (props, ctx) {
    const giftList = shallowRef()
    const sortGift = R.sortBy(R.prop('giftSort'))
    const refreshList = () => {
      readGiftList({
        page: 0,
        pageCount: 100,
      }).then((resp) => {
        const list = sortGift(resp || [])
        giftList.value = R.splitEvery(3, list)
        giftList.value = R.splitEvery(2, giftList.value)
      })
    }

    refreshList()

    const handleExchange = (item: GiftItem) => {
      if (!isAbleToPlay()) {
        ctx.emit('close')
        return false
      }

      if (leftPoints.value < item.poins) {
        noPointAlert()
      } else {
        openDialog(TheGiftConfirmDialog, {
          gift: item,
          onClose: () => {
            ctx.emit('close')
          },
        }, {
          touchToClose: true,
        })
      }
    }

    return {
      giftList,
      leftPoints,
      handleExchange,
    }
  },
})
