<template>
  <div class="bg-theme px-16 py-24">
    <div class="cd-head df-middle" style="height: 24px;">
      <t path="quickplaylive_38" class="c-title">My points:</t>
      <span class="f-bold ml-8">{{leftPoints}}pts</span>
    </div>

    <div class="t-progress d-f br-lg mt-16">
      <div class="line-wrap mx-16 f-md f-bold">
        <div class="g-line"></div>
        <div class="p-line" :style="`width: ${persent}%`"></div>
        <div class="l-d" :style="`left: ${persent}%`"></div>

        <span class="c-p p-a"
          :style="`left: ${persent}%;
          top: ${persent > 86 ? '-16px': '10px'};
          ${persent > 10 ? 'transform: translateX(-100%);' : 'transform: translateX(-50%);'}`"
        >
          {{ completedPoints }}pts
        </span>
        <span class="a-p p-a">{{ taskPoints }}pts</span>
      </div>
    </div>

    <div class="t-list mt-8">
      <div class="l-item df-middle df-between"
        v-for="item in unCompleted" :key="item.id"
      >
        <span>{{ item.name }}</span>
        <span class="c-success f-bold">+{{ item.points }}pts</span>
      </div>
    </div>
    <Line class="my-8" />
    <div class="finish-list c-tip">
      <div class="l-item df-middle df-between"
        v-for="item in completed" :key="item.id"
      >
        <span>{{ item.name }}</span>
        <div class="df-middle">
          <span>+{{ item.points }}pts</span>
          <div class="f-icon ml-8">
            <Icon sm name="check" class="c-white" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, shallowRef } from 'vue'
import { leftPoints } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import Line from '@/components/Line.vue'
import Icon from '@/components/Icon.vue'

import { readLiveTask, LiveTaskItem } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'

export default defineComponent({
  name: 'TheLiveTask',
  components: {
    Line,
    Icon,
  },
  setup () {
    const persent = shallowRef(0)
    const taskPoints = shallowRef(0)
    const completedPoints = shallowRef(0)

    const completed = shallowRef<LiveTaskItem[]>([])
    const unCompleted = shallowRef<LiveTaskItem[]>([])

    readLiveTask().then(res => {
      res.tasks.map((item) => {
        if (item.isCompleted === 1) {
          completed.value.push(item)
        }
        if (item.isCompleted === 0) {
          unCompleted.value.push(item)
        }

        taskPoints.value = res.taskPoints
        completedPoints.value = res.completedPoints
        persent.value = (completedPoints.value / taskPoints.value) * 100
        persent.value = persent.value > 100 ? 100 : persent.value
      })
    })

    return {
      persent,
      taskPoints,
      completedPoints,
      completed,
      unCompleted,
      leftPoints,
    }
  },
})
</script>

<style scoped lang='scss'>
.t-progress{
  background: var(--color-background);
  height: 56px;
  .line-wrap{
    position: relative;
    width: 100%;
    margin-top: 20px;
    .g-line{
      background: #DDE4EB;
      height: 6px;
      border-radius: 4px;
    }

    .p-line{
      background: var(--color-success);
      height: 6px;
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
    }

    .l-d{
      width: 12px;
      height: 12px;
      background: var(--color-success);
      border-radius: 50%;
      position: absolute;
      top: -3px;
      margin-left: -6px;
    }
    .c-p{
      color: var(--color-success);
      top: 10px;
    }
    .a-p{
      right: 0;
      top: 10px;
      color: var(--color-title);
    }
  }
}

.l-item{
  height: 36px;
  .f-icon{
    width: 16px;
    height: 16px;
    background: var(--color-success);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
