
import { marketFeed } from '@/common/datafeed'
import Icon from '@/components/Icon.vue'
import { openDialog } from '@/components/popup/popup'
import { ProductSchema, readProducts } from '@/modules/fastTrade/fastTrade.api'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import * as R from 'ramda'
import { defineComponent, shallowRef, watch, onBeforeUnmount } from 'vue'
import TheQuickPlayCodes from '@/pages/liveForQuickPlay/components/quickPlayInLive/TheQuickPlayCodes.vue'

import { isAbleToPlay, isExpand, curruntCode, productList } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import TheQuickPlayInLive from '@/pages/liveForQuickPlay/components/TheQuickPlayInLive.vue'

export default defineComponent(
  {
    name: 'TheCodeSelector',
    components: { CurrentQuote, Icon, RealTimeQuote },
    props: {
      code: {
        type: String,
        required: true,
      },
    },
    emits: ['change', 'close'],
    setup (props, ctx) {
      const products = shallowRef<ProductSchema[]>([])
      const product = shallowRef<ProductSchema | null>(null)

      const watchInit = () => {
        if (curruntCode.value === '') {
          readProducts().then(resp => {
            productList.value = resp
            curruntCode.value = resp[0].contract
            marketFeed.subscribe([resp[0].contract])
            product.value = resp[0]
          })
        } else {
          product.value = R.find(R.propEq('contract', curruntCode.value), productList.value) || null
          marketFeed.subscribe([curruntCode.value])
        }
      }

      watch(() => curruntCode.value, () => {
        watchInit()
      }, {
        immediate: true,
      })

      readProducts().then(resp => {
        products.value = resp
        marketFeed.subscribe(R.pluck('contract', resp))
      })

      const openSelect = () => {
        if (!isAbleToPlay()) {
          return
        }
        openDialog(TheQuickPlayCodes, {
          wrapperClass: 'middle',
          onChange: (val: ProductSchema) => {
            ctx.emit('change', val)
            product.value = val
          },
        }, {
          transition: 'slide-down',
          touchToClose: true,
        })
      }

      const handleExpand = () => {
        if (!isAbleToPlay()) {
          return
        }
        isExpand.value = !isExpand.value
        if (isExpand.value) {
          openDialog(TheQuickPlayInLive, {}, {
            touchToClose: true,
          })
        } else {
          // close
          ctx.emit('close')
        }
      }

      onBeforeUnmount(() => {
        marketFeed.unSubscribe([], true)
      })

      return {
        products,
        product,
        isExpand,
        openSelect,
        handleExpand,
      }
    },
  },
)
