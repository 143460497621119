<template>
<div>
  <video webkit-playsinline
    playsinline ref="video"
    poster="/img/live/video_bg.png"
  >
  </video>
</div>
</template>

<script lang="ts">
import 'video.js/dist/video-js.min.css'
import { stopVideo } from '@/common/jsBridge.api'
import { defineComponent, nextTick, onBeforeUnmount, shallowRef, watch } from 'vue'
import videojs, { VideoJsPlayer } from 'video.js'

export default defineComponent({
  name: 'TheLivePlayer',
  props: {
    address: {
      type: String,
    },
  },
  setup (props) {
    const video = shallowRef()
    const playing = shallowRef(true)
    let player: VideoJsPlayer | null = null
    const playPromise = shallowRef()

    watch(() => props.address, (val) => {
      if (!val) {
        stopVideo()
        playing.value = false
      } else {
        nextTick(() => {
          if (video.value) {
            player = videojs(video.value, {
              fill: true,
              controls: false,
              bigPlayButton: false,
              sources: [{
                src: val,
                type: 'application/x-mpegURL',
              }],
            }, () => {
              player?.loadingSpinner?.ready(() => {
                document.getElementsByClassName('vjs-control-text').item(0)?.remove()
              })
              player?.bigPlayButton?.hide()
              player?.ready(() => {
                playPromise.value = player?.play()
              })
            })
          }
        })
      }
    }, {
      immediate: true,
    })

    onBeforeUnmount(() => {
      if (playPromise.value !== undefined) {
        playPromise.value.then(() => {
          player?.pause()
          player?.dispose()
        })
      }
    })

    return {
      video,
      playing,
    }
  },
})
</script>

<style scoped lang="scss">
video {
  width: 100vw;
  height: calc(100vh - 56px);
  object-fit: cover;
}

::v-deep(.vjs-control-text){
  display: none;
}
</style>
