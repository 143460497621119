<template>
  <div class="df-middle">
    <div class="check-status round mr-8" :class="{active}"></div>
    <p :class="{'f-bold': active}">
      <slot  />
    </p>
  </div>
</template>

<script lang="ts">
export default {
  name: 'TheCheckItem',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style scoped lang="scss">
.check-status{
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-border);
  &.active{
    border-color: var(--color-primary);
    position: relative;
  }
  &.active::after{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-color: var(--color-primary);
    content: '';
    border-radius: 50%;
  }
}
</style>
