
import { defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import Switch from '@/modules/membership/signal/components/Switch.vue'
import Button from '@/components/Button.vue'
import { keymap } from '@/config'
import { localSet } from 'essential/store/localStore'

import { liveSetting, IMConfig, refreshIMconfig } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { useSaveLiveSetting, readSetting } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheLiveSetting',
  components: {
    DialogTemplate,
    Switch,
    Button,
  },
  setup (props, ctx) {
    const { request, progress } = useSaveLiveSetting()
    const route = useRoute()

    readSetting().then(res => {
      liveSetting.value = res
    })

    const handleSave = () => {
      if (liveSetting.value.name !== IMConfig.value?.name) {
        IMConfig.value = null
      }
      request(liveSetting.value).then(() => {
        localSet(keymap.user.nickName, liveSetting.value.name)
        if (IMConfig.value) {
          IMConfig.value.name = liveSetting.value.name as string
        }
        refreshIMconfig(route.params.chatRoomId as string)
        ctx.emit('close')
      })
    }

    return {
      liveSetting,
      progress,
      handleSave,
    }
  },
})
