<template>
  <div class="guide-con t-text mb-20 f-nm">
    <div v-if="s === 1">
      <Pic src="live/Guide1" class="mb-10" />
      <t as="p" path="quickplaylive_3">1. Browse investors and view their details</t>
      <t as="p" path="quickplaylive_4">1. Browse investors and view their details</t>
    </div>
    <div v-else-if="s === 2">
      <Pic src="live/Guide2" class="mb-10" />
      <t as="p" path="quickplaylive_5">1. Browse investors and view their details</t>
    </div>
    <div v-else-if="s === 3">
      <Pic src="live/Guide3" class="mb-10" />
      <t as="div" path="quickplaylive_6" multiline #="{td}" custom>
        <p as="p" v-for="(p, index) in td" :key="index"
          :class="{'mt-8': index === 1}"
        >
          {{p}}
        </p>
      </t>
    </div>
    <div v-else-if="s === 4">
      <Pic src="live/Guide4" class="mb-10" />
      <t as="p" path="quickplaylive_7">
        3. Confirm to follow an investor and wait for their trading actions
      </t>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheLiveGuideContent',
  components: { Pic },
  props: {
    s: {
      type: Number,
      default: 1,
    },
  },
})
</script>

<style scoped lang="scss">
  .guide-con {
    line-height: 24px;

    img {
      width: 100%;
      display: block;
    }
  }
</style>
