<template>
  <div class="bg-theme px-16">
    <div class="">
      <p class="t-center f-bold f-lg py-12">
        <t path="">Trade with Insurance</t>
      </p>
      <Line />
    </div>

    <div class="d-body pt-16 pb-32">
      <div class="df-center">
        <div class="time df-middle">
          <div class="t-item">{{time.hh}}</div>
          <span class="mx-4 f-bold f-xxl">:</span>
          <div class="t-item">{{time.mm}}</div>
          <span class="mx-4 f-bold f-xxl">:</span>
          <div class="t-item">{{time.ss}}</div>
        </div>
      </div>
      <div class="mt-16">
        <t as="div" path="quickplaylive_73" multiline #="{td}" custom>
          <p class="mb-8" v-for="(p, index) in td" :key="index">{{p}}</p>
        </t>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onUnmounted, reactive, watch } from 'vue'
import Line from '@/components/Line.vue'
import { handleTime } from '@/pages/liveForQuickPlay/liveForQuickPlay'

export default defineComponent({
  name: 'TheInsurence',
  components: {
    Line,
  },
  props: {
    left: Number,
  },
  setup (props) {
    const time = reactive({
      hh: '00',
      mm: '00',
      ss: '00',
      leftTime: 0,
      timer: 0,
    })

    const timeLoop = () => {
      time.hh = handleTime(time.leftTime).hh
      time.mm = handleTime(time.leftTime).mm
      time.ss = handleTime(time.leftTime).ss
      time.timer = window.setInterval(() => {
        time.leftTime--
        if (time.leftTime === 0) {
          clearInterval(time.timer)
          return false
        }
        time.hh = handleTime(time.leftTime).hh
        time.mm = handleTime(time.leftTime).mm
        time.ss = handleTime(time.leftTime).ss
      }, 1000)
    }

    watch(() => props.left, () => {
      time.leftTime = props.left as number
      timeLoop()
    }, {
      immediate: true,
    })

    onUnmounted(() => {
      clearInterval(time.timer)
    })

    return {
      time,
    }
  },
})
</script>

<style scoped lang='scss'>
.time{
  .t-item{
    width: 26px;
    height: 26px;
    background: var(--color-text);
    border-radius: 4px;
    font-weight: bold;
    color: var(--color-theme);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
