<template>
  <div class="gift-ani df-middle" ref="aniDom">
    <Transition name="bounce">
      <div class="g-content df-middle df-center fill" v-if="showGiftAni">
        <div class="g-img">
          <Pic :src="gift.giftPic" width="128" height="128" />
        </div>
        <div class="g-count c-white">X{{gift.count}}</div>
      </div>
    </Transition>
  </div>
</template>

<script lang='ts'>
import { defineComponent, watch, shallowRef, nextTick, onUnmounted } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheGiftAnimation',
  components: {
    Pic,
  },
  props: {
    gift: Object,
  },
  setup (props) {
    const showGiftAni = shallowRef(false)
    const aniDom = shallowRef<HTMLElement | null>(null)
    let timer = 0

    watch(() => props.gift, () => {
      if (aniDom.value) {
        aniDom.value.style.display = 'block'
      }
      if (props.gift) {
        nextTick(() => {
          showGiftAni.value = true
          timer = window.setTimeout(() => {
            showGiftAni.value = false
            if (aniDom.value) {
              aniDom.value.style.display = 'none'
            }
          }, 2000)
        })
      }
    }, {
      immediate: true,
      deep: true,
    })

    onUnmounted(() => {
      clearTimeout(timer)
      if (aniDom.value) {
        aniDom.value.style.display = 'none'
      }
    })

    return {
      showGiftAni,
      aniDom,
    }
  },
})
</script>

<style>
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
</style>
<style scoped lang='scss'>
.gift-ani{
  height: 128px;
  width: 100%;
  position: fixed;
  top: 50%;
  margin-top: -128px;
  left: 0;
  z-index: 2;
  .g-count{
    font-size: 72px;
    font-style: italic;
    margin-left: 16px;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.8s;
}
.bounce-leave-active {
  animation: bounce-out 0.5s;
}
</style>
