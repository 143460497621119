
import { defineComponent, shallowRef, nextTick, onBeforeUnmount, onMounted } from 'vue'
import TheLike from '@/pages/liveForQuickPlay/components/TheLike.vue'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'
import TheEmoji from '@/modules/community/communities/components/TheEmoji.vue'
import { openDialog } from '@/components/popup/popup'

import TheLiveSetting from '@/pages/liveForQuickPlay/components/TheLiveSetting.vue'
import TheExchangeCoupon from '@/pages/liveForQuickPlay/components/TheExchangeCoupon.vue'
import TheGiftDialog from '@/pages/liveForQuickPlay/components/TheGiftDialog.vue'
import { readExchangeCouponList, readFastCommentList } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { leftPoints, useIMsend } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'TheMessages',
  components: {
    TheLike,
    Pic,
    Icon,
    TheEmoji,
    Button,
  },
  setup () {
    const shwoTrueInput = shallowRef(false)
    const useEmoji = shallowRef(false)
    const text = shallowRef('')
    const showFastChat = shallowRef(false)
    const showRedPoint = shallowRef(false)

    const fastList = shallowRef<{ comment: string }[]>([])

    const openFastChat = () => {
      shwoTrueInput.value = false
      useEmoji.value = false
      if (fastList.value.length === 0) {
        return
      }
      showFastChat.value = !showFastChat.value
    }

    readExchangeCouponList({
      page: 0,
      pageCount: 100,
    }).then((resp) => {
      resp.noVoucherModels.forEach(item => {
        if (item.poins < leftPoints.value) {
          showRedPoint.value = true
        }
      })
    })

    readFastCommentList().then(res => {
      fastList.value = res || []
    })

    const openTrueInput = (withemoji?: boolean) => {
      shwoTrueInput.value = true

      if (withemoji) {
        useEmoji.value = true
      } else {
        nextTick(() => {
          const trueInput = document.getElementById('trueInput')
          trueInput?.focus()
        })
      }
    }

    const openSettingDialog = () => {
      openDialog(TheLiveSetting, null, {
        touchToClose: true,
      })
    }

    const openCouponDialog = () => {
      showRedPoint.value = false
      openDialog(TheExchangeCoupon, null, {
        touchToClose: true,
      })
    }

    const openGiftDialog = () => {
      openDialog(TheGiftDialog, null, {
        touchToClose: true,
      })
    }

    /* 通过接口发送消息---先发后删功能 */
    const sending = shallowRef(false)
    const comment = () => {
      if (sending.value) return
      sending.value = true

      text.value = text.value.replace(/#wt-msg#/g, '') // 过滤自定义特殊消息，避免用户手动输入触发特效
      text.value = text.value.replace(/#system#/g, '') // 过滤自定义特殊消息，避免用户手动输入触发特效

      useIMsend(text.value, () => {
        text.value = ''
        sending.value = false
        shwoTrueInput.value = false
        useEmoji.value = false
      })
    }

    const handleHideTrueInput = () => {
      shwoTrueInput.value = false
      useEmoji.value = false
    }

    const sendFastChat = (text: string) => {
      useIMsend(text, () => {
        shwoTrueInput.value = false
        useEmoji.value = false
        showFastChat.value = false
      })
    }

    onMounted(() => {
      window.addEventListener('click', () => {
        if (showFastChat.value) {
          showFastChat.value = false
        }
      })
    })

    onBeforeUnmount(() => {
      window.removeEventListener('click', () => {
        // remove listener
      })
    })

    return {
      shwoTrueInput,
      useEmoji,
      text,
      showFastChat,
      showRedPoint,
      fastList,

      comment,
      openFastChat,
      openTrueInput,
      openSettingDialog,
      openCouponDialog,
      openGiftDialog,
      handleHideTrueInput,
      sendFastChat,
    }
  },
})
