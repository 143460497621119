<template>
  <div class="px-12 gift-wrap df-col" ref="listEl">

  </div>
</template>

<script lang='ts'>
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'
import idMaker from 'essential/tools/idMaker'
import { off, on } from 'essential/tools/event'
import { events } from '@/config'
import * as R from 'ramda'

export default defineComponent({
  name: 'TheGiftContent',
  components: {
  },
  setup () {
    const giftList = shallowRef<Record<string, string>[]>([])
    const listEl = shallowRef<HTMLElement>()

    const giftShowTime = 1000 * 3 // 默认礼物展示时长
    const loopTime = 100 // 循环查询队列中剩余礼物消息的时长

    let timer = 0
    let checkLen = 0

    on(events.liveGiftMsg, (res) => {
      try {
        const msg = JSON.parse(res)
        handleInsert(msg)
      } catch {
        return false
      }
    })

    const ID = idMaker()

    const handleInsert = (msg: Record<string, string>) => {
      const newId = ID.next()
      giftList.value.push({
        ...msg,
        id: newId.toString(),
      })
      handleLoop()
    }

    const handleLoop = () => {
      if (checkLen === 0) {
        checkLen = window.setInterval(() => {
          if (giftList.value.length === 0) {
            clearInterval(checkLen)
            checkLen = 0
            return
          }

          const itemLen = document.getElementsByClassName('gift-item').length
          if (itemLen < 3) {
            insertNewItem(giftList.value[0])
          }
        }, loopTime)
      }
    }

    const insertNewItem = (msg: Record<string, string>) => {
      const newItem = document.createElement('div')
      const gfImg = document.createElement('img')

      // set gift img
      gfImg.src = msg.giftPic

      const bg = document.createElement('div')
      const nikename = document.createElement('p')
      const count = document.createElement('span')

      // set nikename
      nikename.innerText = msg.nickname

      // set count
      count.innerText = 'X' + msg.count

      bg.classList.add('g-bg')
      newItem.appendChild(bg)
      newItem.appendChild(gfImg)
      newItem.appendChild(nikename)
      newItem.appendChild(count)
      newItem.id = 'item_' + msg.id
      newItem.classList.add('gift-item')
      newItem.classList.add('mt-4')
      newItem.classList.add('inert')
      listEl.value?.appendChild(newItem)

      const index = R.findIndex(R.propEq('id', msg.id))(giftList.value)

      giftList.value = giftList.value.splice(index + 1)

      timer = window.setTimeout(() => {
        handleClose(Number(msg.id))
      }, giftShowTime)
    }

    const handleClose = (id: number) => {
      document.getElementById('item_' + id)?.remove()
    }

    onBeforeUnmount(() => {
      clearTimeout(timer)
      clearInterval(checkLen)
      off(events.liveGiftMsg)
    })

    return {
      handleInsert,
      giftList,
      listEl,
    }
  },
})
</script>

<style>
  @keyframes insert-ani{
    0% {
      left: -100%;
    }
    100% {
      left: 12px;
    }
  }

  @keyframes remove-ani-f{
    0% {
      top: 16px;
    }
    100% {
      top: -80px;
    }
  }

  @keyframes remove-ani-s{
    0% {
      top: 84px;
    }
    100% {
      top: 16px;
    }
  }
</style>

<style lang='scss' scope>
.gift-wrap{
  height: 204px;
  overflow: hidden;
  justify-content: flex-end;
}
.gift-item{
  height: 64px;
  position: relative;
  transform: 0.5s;
  .g-bg{
    width: 160px;
    height: 48px;
    background: linear-gradient(90deg, #6206FF 0%, #FF0096 100%);
    border-radius: 26px;
    opacity: 0.8;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  img{
    position: absolute;
    width: 64px;
    height: 64px;
    bottom: 0;
    left: 0;
  }
  p{
    position: absolute;
    color: var(--color-white);
    left: 68px;
    top: 18px;
    max-width: 78px;
    min-width: 78px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  span{
    position: absolute;
    font-size: 24px;
    color: var(--color-white);
    left: 68px;
    top: 34px;
  }
}
.inert{
  animation-name: insert-ani;
  animation-duration: .4s;
  animation-fill-mode: forwards;
}

.remove-f{
  animation-name: remove-ani-f;
  animation-duration: .4s;
  animation-fill-mode: forwards;
}

.remove-s{
  animation-name: remove-ani-s;
  animation-duration: .4s;
  animation-fill-mode: forwards;
}
</style>
