/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/27
* @description
*   TheAddChatNickNameDialog.vue of WeTrade
*/
<template>
  <DialogTemplate>
    <template #title>
      <t path="live_10">Nickname</t>
    </template>
    <div class="flex-1">
      <t as="p" path="live_11" class="mt-24">You need to set a nickname before entering the chat room.</t>
      <div class="form-item block">
        <input type="text" class="input" v-model="name" :placeholder="old">
      </div>
      <div class="wedge" style="height: 48px"></div>
    </div>
    <template #cancelText>Exit</template>
    <template #confirm>
      <Button class="flex-1 btn block primary" :progress="progress" @click="update">Save</Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import { keymap } from '@/config'
import useRequest from '@/hooks/useRequest'
import { updateNickname } from '@/pages/setting/setting'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { localGet } from 'essential/store/localStore'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheAddChatNickNameDialog',
  components: { Button, DialogTemplate },
  setup (props, ctx) {
    const old = localGet(keymap.user.nickName)
    const name = shallowRef('')
    const [update, progress] = useRequest(updateNickname)

    return {
      old,
      name,
      update () {
        update(name.value).then(() => {
          ctx.emit('close')
          ctx.emit('confirm')
        })
      },
      progress,
    }
  },
})
</script>
