
import { defineComponent, onUnmounted, shallowRef } from 'vue'
import { openDialog } from '@/components/popup/popup'
import TheLikeDialog from '@/pages/liveForQuickPlay/components/TheLikeDialog.vue'
import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import { sendLike as callSendLike } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { useRoute } from 'vue-router'
import { leftPoints, useIMsend } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import { noPointAlert } from '@/pages/liveForQuickPlay/components/liveAlert/liveAlert'
import { refreshAccount } from '@/modules/app/app'

export default defineComponent({
  name: 'TheLike',
  components: {
  },
  props: {
    points: Number,
  },
  setup () {
    const canvas = shallowRef()
    let loadTime = 0
    let countTime = 0
    let touchTime = 0

    let timeCount = 0
    const totalLikeNum = shallowRef(0)

    let repeatRate = 200

    const route = useRoute()

    // canvas loading动画效果
    const loadAnimation = (finish?: () => void) => {
      clearInterval(loadTime)
      loadTime = 0
      if (canvas.value) {
        const ctx = canvas.value.getContext('2d') as CanvasRenderingContext2D
        const ratio = window.devicePixelRatio || 2
        canvas.value.height = 40 * ratio
        canvas.value.width = 40 * ratio
        canvas.value.style.height = `${40}px`
        canvas.value.style.width = `${40}px`
        ctx.scale(ratio, ratio)

        const gr = ctx.createLinearGradient(0, 0, 100, 0)
        gr.addColorStop(0, '#FFCE00')
        gr.addColorStop(0.5, '#FF47A8')
        gr.addColorStop(1, '#FFCE00')
        ctx.lineWidth = 4
        ctx.strokeStyle = gr

        let persent = 0

        const drawLine = (persent: number) => {
          ctx.restore()
          ctx.beginPath()
          ctx.arc(20, 20, 18, -Math.PI * 0.5, -Math.PI * 0.5 + (Math.PI * 2 * (persent / 100)), false)
          ctx.stroke()
          ctx.closePath()
          ctx.save()
        }

        loadTime = window.setInterval(() => {
          persent++
          ctx.clearRect(0, 0, 40, 40)
          drawLine(persent)
          if (persent >= 100) {
            clearInterval(loadTime)
            ctx.clearRect(0, 0, 40, 40)
            finish?.()
          }
        }, 30)
      }
    }

    const likeRepeat = () => {
      countTime = window.setInterval(() => {
        if (totalLikeNum.value >= leftPoints.value) {
          clearAll()
          if (canvas.value) {
            const ctx = canvas.value.getContext('2d') as CanvasRenderingContext2D
            ctx.clearRect(0, 0, 40, 40)
          }
          noPointAlert()
        } else {
          totalLikeNum.value = totalLikeNum.value + 1
          if (totalLikeNum.value >= 15) {
            repeatRate = 100
            clearInterval(countTime)
            likeRepeat()
          }
        }
      }, repeatRate)
    }

    const likeOnce = () => {
      totalLikeNum.value = totalLikeNum.value + 1
    }

    const handleTouchStart = () => {
      if (canvas.value) {
        clearInterval(loadTime)
        const ctx = canvas.value.getContext('2d') as CanvasRenderingContext2D
        ctx.clearRect(0, 0, 40, 40)
      }

      timeCount = 0
      touchTime = window.setInterval(() => {
        timeCount = timeCount + 100
        if (timeCount >= 300) {
          clearInterval(touchTime)
          likeRepeat()
        }
      }, 100)
    }

    const handleTouchEnd = () => {
      clearInterval(touchTime)
      clearInterval(countTime)

      if (timeCount < 300) {
        likeOnce()
      }

      loadAnimation(() => {
        sendLike()
      })

      timeCount = 0
      repeatRate = 200
    }

    const sendLike = () => {
      if (totalLikeNum.value === 0) return
      const isFirst = localGet(keymap.guide.liveLike)

      if (!isFirst) {
        openDialog(TheLikeDialog, {
          onCancel: () => {
            clearAll()
          },
          onOk: () => {
            localSet(keymap.guide.liveLike, '1')
            handleSend()
          },
        })
        return false
      }

      handleSend()
    }

    const handleSend = () => {
      callSendLike({
        roomid: route.params.chatRoomId,
        like: totalLikeNum.value,
      }).then((res) => {
        if (res.like > 0) {
          useIMsend(`:live_like*${totalLikeNum.value}:`)
          refreshAccount()
        } else {
          noPointAlert()
        }
      }).finally(() => {
        clearAll()
      })
    }

    // 中断touch事件
    const handleTouchCancel = () => {
      clearAll()
    }

    const clearAll = () => {
      repeatRate = 200
      totalLikeNum.value = 0
      clearInterval(loadTime)
      clearInterval(countTime)
      clearInterval(touchTime)
    }

    // 卸载时清除计时器
    onUnmounted(() => {
      clearAll()
    })

    return {
      canvas,
      totalLikeNum,
      handleTouchStart,
      handleTouchEnd,
      handleTouchCancel,
    }
  },
})
