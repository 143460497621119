
import { defineComponent, onUnmounted, shallowRef } from 'vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { openDialog } from '@/components/popup/popup'
import TheRankDialog from '@/pages/liveForQuickPlay/components/TheRankDialog.vue'
import { readLiveRank, RankType } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheRankPreview',
  components: {
    Icon,
    Pic,
  },
  setup () {
    const rankList = shallowRef()
    const route = useRoute()

    const getList = () => {
      readLiveRank({
        roomid: route.params.chatRoomId,
        type: RankType.TODAY,
      }).then(res => {
        rankList.value = res.liveSendGiftRankInfoModels || []
        rankList.value.length = 5
        rankList.value = rankList.value.reverse()
      })
    }
    getList()

    let timer = 0
    timer = window.setInterval(() => {
      getList()
    }, 1000 * 60)

    const openRankList = () => {
      openDialog(TheRankDialog, null, {
        touchToClose: true,
      })
    }

    onUnmounted(() => {
      clearInterval(timer)
    })

    return {
      rankList,
      openRankList,
    }
  },
})
