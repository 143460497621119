<template>
  <div class="pt-16">
    <div class="df-middle df-between px-16">
      <div class="d-f flex-1 br-lg" style="overflow: hidden;">
        <t as="button" path="quickplaylive_25" class="btn flex-1" :class="[isBuy && 'success']"
          style="height: 40px;"
          @click="type = TradeDirection.BUY">Buy
        </t>
        <t as="button" path="quickplaylive_26" class="btn flex-1"
          style="height: 40px;"
          :class="[!isBuy && 'danger']"
          @click="type = TradeDirection.SELL">Sell
        </t>
      </div>

      <TheFiniteSelector class="ml-24" :options="products" @change="handleFiniteChange">
        <template #default="{item}">
          <money :v="item.price" class="f-bold" />
        </template>
      </TheFiniteSelector>
    </div>

    <div class="p-16 mt-12">
      <div class="df-between type" :class="{'f-bold': useCash, disabled: !cashUsable}"
        @click="handleUseCash"
      >
        <TheCheckItem :active="useCash">
          <div class="df-middle">
            <t path="quickplaylive_21" class="ml-8">Margin Available</t>
            <money class="df-middle ml-4" :v="free" />
          </div>
        </TheCheckItem>

        <AccountRelated use-in-real>
          <Button class="d-btn" @click="pageToIn">
            <t path="quickplaylive_23" custom #="{td}">
              {{ td || 'Deposit' }}
            </t>
          </Button>
        </AccountRelated>

      </div>
      <AccountRelated use-in-real>
        <div
          class="d-f type"
          :class="{'f-bold': useCoupon, disabled: !usableCouponCount}"
          @click="handleUseCoupon"
        >
          <TheCheckItem :active="useCoupon">
            <div class="df-middle">
              <money :v="couponPrice" #={text}>
                <t path="placeorder_12" :args="{coupon: text}" class="ml-8">{{ value }}Coupons</t>
              </money>
              <div class="ml-4">({{usableCouponCount}})</div>
            </div>
          </TheCheckItem>
        </div>
      </AccountRelated>
    </div>

    <Button class="btn block f-bold f-lg mt-24" :class="{'primary': (useCash || useCoupon)}" :progress="progress" @click="commit">
      <t path="quickplaylive_24" #="{td}" custom>{{ td || 'Place Order' }}</t>
    </Button>
  </div>
</template>

<script lang='ts'>
import { TradeDirection, YesOrNo } from '@/types'
import Button from '@/components/Button.vue'
import { openDialog } from '@/components/popup/popup'
import useRequest from '@/hooks/useRequest'
import { translate } from '@/i18n'
import TheFiniteSelector from '@/modules/fastTrade/components/TheFiniteSelector.vue'
import { Product, generateProducts } from '@/modules/fastTrade/fastTrade'
import { create, ProductSchema } from '@/modules/fastTrade/fastTrade.api'
import { computed, defineComponent, shallowRef, watchEffect } from 'vue'
import { isDemoAccount } from '@/state/accountType'
import state from '@/state'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import * as R from 'ramda'

import TheCheckItem from '@/pages/setting/components/TheCheckItem.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import { product, liveSetting, checkLiveUsableCoupon } from '@/pages/liveForQuickPlay/liveForQuickPlay'
import TheOrderConfirm from '@/pages/liveForQuickPlay/components/TheOrderConfirm.vue'
import { useRoute } from 'vue-router'
import { getPointAalert, GetPointAlertEnum } from '@/pages/liveForQuickPlay/components/liveAlert/liveAlert'
import router from '@/router'
import { refreshAccount } from '@/modules/app/app'
import {
  checkLimitEvent,
  LimitEventType,
} from '@/pages/liveForQuickPlay/liveForQuickPlay.api'

export default defineComponent({
  name: 'TheLiveTradePanel',
  components: {
    TheFiniteSelector,
    AccountRelated,
    Button,
    TheCheckItem,
  },
  emits: ['created', 'typeChanged'],
  setup (props, ctx) {
    const type = shallowRef(TradeDirection.BUY)
    const products = shallowRef<Product[]>([])
    const useCoupon = shallowRef(false)
    const useCash = shallowRef(true)
    const usableCouponCount = shallowRef(0)
    const couponPrice = shallowRef(0)
    const cashUsable = shallowRef(false)

    const route = useRoute()

    const curruntProduct = shallowRef<Product>()
    const isPrime = computed(() => state.prime.member === YesOrNo.YES)
    const isBuy = computed(() => type.value === TradeDirection.BUY)

    const free = computed(() => R.min(state.account.balance, state.account.freeMargin))

    products.value = generateProducts(product.value as ProductSchema)

    watchEffect(() => {
      ctx.emit('typeChanged', type.value)
    })

    const [_commit, progress] = useRequest(create)

    const commit = () => {
      if (!useCoupon.value && !useCash.value) {
        return
      }

      if (liveSetting.value.orderConfirmation) {
        openDialog(TheOrderConfirm, {
          order: {
            fee: curruntProduct.value?.fee,
            direction: type.value,
            invest: curruntProduct.value?.price,
            useCoupon: useCoupon.value,
            stopProfitOffset: curruntProduct.value?.stopProfitOffset,
            stopLossOffset: curruntProduct.value?.stopLossOffset,
            ...product.value,
          },
          onOk: () => {
            createRequest()
          },
        })
      } else {
        createRequest()
      }
    }

    const createRequest = () => {
      const params = {
        code: curruntProduct.value?.code,
        price: curruntProduct.value?.price,
        voucher: Number(useCoupon.value),
        type: type.value,
        roomId: route.params.chatRoomId,
      }

      if (!isDemoAccount.value && !isPrime.value) {
        openDialog(LimitDialogTemplate, {
          i18nPath: 'quickplay_52',
        })
        return false
      } else {
        return _commit(params).then(resp => {
          const pointsDesc = resp.rewardPoints || 0
          getPointAalert(GetPointAlertEnum.DEFAULT, {
            title: translate('quickplaylive_15', 'The order has been placed'),
            point: pointsDesc,
          })
          checkCoupon()
          refreshAccount()
          ctx.emit('created', resp)
        })
      }
    }

    const handleUseCash = () => {
      useCoupon.value = false
      useCash.value = true
    }

    const handleUseCoupon = () => {
      useCash.value = false
      useCoupon.value = true
    }

    const handleFiniteChange = (v: Product) => {
      curruntProduct.value = v
      if (free.value < Number(v.price)) {
        useCash.value = false
        cashUsable.value = false
      } else {
        cashUsable.value = true
      }
      couponPrice.value = Number(v.price)
      checkCoupon()
    }

    const checkCoupon = () => {
      checkLiveUsableCoupon().then(resp => {
        usableCouponCount.value = R.length(R.filter(R.propEq('price', Number(curruntProduct.value?.price)), resp))
        if (!usableCouponCount.value) {
          useCoupon.value = false
        }
      })
    }

    const pageToIn = () => {
      router.back()

      checkLimitEvent({
        roomid: route.params.chatRoomId,
      }).then(res => {
        console.log(res)
        if (res.length === 0) {
          router.push('/in?roomid=' + route.params.chatRoomId)
          return
        }
        res.forEach(item => {
          if (item.type === LimitEventType.DEPOSIT) {
            if (item.times > 0) {
              router.push('/in?roomid=' + route.params.chatRoomId + '&during=true')
            } else {
              router.push('/in?roomid=' + route.params.chatRoomId)
            }
          }
        })
      })
    }

    return {
      isBuy,
      useCoupon,
      useCash,
      usableCouponCount,
      couponPrice,
      cashUsable,
      progress,
      curruntProduct,
      products,
      TradeDirection,
      type,
      free,
      commit,
      handleUseCash,
      handleUseCoupon,
      handleFiniteChange,
      pageToIn,
    }
  },
})
</script>

<style scoped lang='scss'>
.type{
  height: 32px;
}
.d-btn{
  height: 32px;
  border-radius: 18px;
  border: 1px solid var(--color-success);
  background-color: var(--color-theme);
  display: flex;
  align-items: center;
  color: var(--color-success);
}
</style>
